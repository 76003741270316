import {LitElement, html} from "lit-element";
import {USER} from "../core/User";
import {unsafeHTML} from "lit-html/directives/unsafe-html";

export class CAPPBanner extends LitElement{

    static BANNER_TYPE = {
        LOGGED_IN:'logged-in',
        LOGGED_OUT:'logged-out'
    }
    
    constructor() {
        super();
        this._bannerData=[];
        this._activeBanner=null;
        this._loggedInState=false;
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        let bannersElements=this.renderRoot.querySelectorAll('div');
        bannersElements.forEach(item=>{
            let bDef={};
            bDef.title=item.querySelector('h3').innerHTML;
            bDef.additionalText=item.querySelector('p').innerHTML;
            bDef.imageUrl=item.querySelector('img').dataset.src;
            if(bDef.imageUrl==""){bDef.imageUrl=undefined;}
            let ctaEl=item.querySelector('a');
            bDef.ctaLabel=ctaEl.innerHTML;
            bDef.ctaUrl=ctaEl.href;
            bDef.type=item.dataset.type;
            this._bannerData.push(bDef);
        });

        this._setActiveBanner();

        USER.addListener("onLoginStateChange",e=>{
            this._setActiveBanner();
        });

        super.connectedCallback();
    }

    render(){
        return html`
            <div class="banner">
                ${this._activeBanner.imageUrl?html`
                    <img alt="Banner Image" loading="lazy" src="${this._activeBanner.imageUrl}">
                `:''}
                <div class="content-wrap">
                    <div class="text-wrap">
                        <h3>${unsafeHTML(this._activeBanner.title)}</h3>
                        <p>${unsafeHTML(this._activeBanner.additionalText)}</p>
                    </div>
                    <div class="cta-wrap">
                        <a class="btn-primary" href="${this._activeBanner.ctaUrl}">${this._activeBanner.ctaLabel}</a>
                    </div>
                </div>
            </div>
        `
    }



    _setActiveBanner(){
        this._activeBanner=null;
        let targetType=USER.isLoggedIn?CAPPBanner.BANNER_TYPE.LOGGED_IN:CAPPBanner.BANNER_TYPE.LOGGED_OUT;
        for(let i=0; i<this._bannerData.length; i++){
            if(this._bannerData[i].type==targetType){
                this._activeBanner=this._bannerData[i];
                break;
            }
        }

        if(this._activeBanner==null){
            for(let i=0; i<this._bannerData.length; i++){
                if(this._bannerData[i].type!=CAPPBanner.BANNER_TYPE.LOGGED_IN
                && this._bannerData[i].type!=CAPPBanner.BANNER_TYPE.LOGGED_OUT){
                    this._activeBanner=this._bannerData[i];
                    break;
                }
            }
        }

        this.requestUpdate();

    }


}

customElements.define('capp-banner', CAPPBanner);