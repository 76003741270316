import {SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {CAPP_API} from "../../core/CAPPApi";
import {APP_STRINGS} from "../../core/AppStrings";
import {GameInfoSubview} from "./GameInfoSubview";
import {Helpers} from "../../util/Helpers";
import {GAHelpers} from "../../util/GAHelpers";

export class SearchSubview extends SubViewBase{
    static POPULAR_SEARCHES=null;

    constructor() {
        super();
        this._searchTerm="";
        this._gameMakerSearchResults = null;
        this._searchResult=null;
        this._loadingGameMakers = false;
        this._loading=false;
        this._recentSearches=this._getRecentSearches();
        this._searchInputEl=null;
        this._populatSearchesLoading=true;
        this._platform=Helpers.getDeviceType();

        GAHelpers.gaEvent('Search','openSearch');

        this._loadPopularSearchTerms();
    }

    firstUpdated(changedProperties) {
        this._initObserver();
        this._searchInputEl=this.renderRoot.querySelector('#search-input');
        this._searchInputEl.focus();
    }

    renderViewContent() {
        return html`
            <div class="search-sub-wrap">
                <div class="search-controls ${(this._loading || this._loadingGameMakers) ?'loading':''}">
                    <img alt="Search Icon" src="${`${CDN_ASSETS_URL}/icons/search.svg`}">
                    <input id="search-input" type="text" .value="${this._searchTerm}" placeholder="${APP_STRINGS.getWPMLString('SearchSubView-search')}" @keyup=${this._onInputChange}>
                    <img class="clear" alt="Clear Search Icon" src="${`${CDN_ASSETS_URL}/icons/cancel.svg`}" @click=${this.clearSearch}>
                    <img class="close" alt="Close Button Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}" @click=${this.close}>
                </div>
                <div class="content-wrap">
                    ${(this._loading || this._loadingGameMakers) ? html`
                        <capp-preloader class="search-loader"></capp-preloader>
                    `:html`
                        ${this._gameMakerSearchResults ? html`
                            ${this._renderGameMakerSearchResults()}
                        ` : ''}
                        ${this._searchResult?html`
                            ${this._renderSearchResults()}
                        `:''}
                        ${this._searchResult==null || this._searchResult.length==0 ? html`
                            ${this._renderRecentSearches()}
                            ${this._renderPopularSearches()}
                        `:``}  
                    `}
                </div>
            </div>
        `;
    }

    _renderGameMakerSearchResults() {
        if (this._gameMakerSearchResults && this._gameMakerSearchResults.length > 0) {
            return html`
            <div class="results results-game-maker">
                ${this._gameMakerSearchResults.map(item=>html`
                    <a class="game-maker-item"
                       href="${item.url}">
                        <img class="icon"
                             src="${item.icon}">
                    </a>
                `)}         
            </div>  
        `;
        } else {
            return html``;
        }
    }

    _renderSearchResults(){
        if(this._searchResult && this._searchResult.length>0){
            return html`
                <div class="results">
                    ${this._searchResult.map(item=>html`
                        <div class="game-item ${item.loading?'loading':''}">
                            <a href="${item.gameLink}" title="${item.shortTitle} ${item.gameCategory}" .item=${item} >
                                <img data-src="${item.lobbyThumbnailUrl}" alt="${item.shortTitle} ${item.gameCategory}" .item=${item}>
                                <h2 class="game-title">${item.shortTitle} <span class="game-category">${item.gameCategory}</span></h2>
                                ${item.eventLogo && item.eventLogo !== ''?html`
                                    <img src="${item.eventLogo}" class="tiles-game-event-logo">
                                `:''}
                            </a>
                            <div class="game-overlay">
                                ${item.loading?html`
                                    <capp-preloader></capp-preloader>
                                `:html`
                                    <img class="play-ic" alt="Play Icon" src="${`${CDN_ASSETS_URL}/icons/play.svg`}">
                                    ${this._platform != "mobile" ? html`
                                    <img class="info-ic" alt="Game Info Icon" src="${`${CDN_ASSETS_URL}/icons/info.svg`}" .item=${item}
                                         @click=${this._onGameInfoClick}>
                                ` : ''}
                                `}
                            </div>
                        </div>
                    `)}         
                </div>  
            `;
        }else{
            return html`<div class="no-results"><span>${APP_STRINGS.getWPMLString('SearchSubView-noSearchResults')}</span></div>`;
        }
    }
    _renderRecentSearches(){
        if(this._recentSearches.length==0){return '';}
        let recents=this._recentSearches.slice(0).reverse();
        return html`
            <div class="search-terms-list">
                <div><h6>${APP_STRINGS.getWPMLString('SearchSubView-recentSearches')}</h6></div>
                <ol>
                    ${recents.map(searchTerm=>html`
                        <li @click=${this._onSearchTermClick}>${searchTerm}</li>
                    `)}
                </ol>
            </div>
        `;
    }

    _renderPopularSearches(){
        if(SearchSubview.POPULAR_SEARCHES !=null && SearchSubview.POPULAR_SEARCHES.length==0){
            return html``;
        }

        return html`
            <div class="search-terms-list">
                <div><h6>${APP_STRINGS.getWPMLString('SearchSubView-popularSearches')}</h6></div>
                ${this._populatSearchesLoading?html`
                <capp-preloader></capp-preloader>
                `:html`
                    <ol>
                        ${SearchSubview.POPULAR_SEARCHES.map(searchTerm=>html`
                            <li @click=${this._onSearchTermClick}>${searchTerm}</li>
                        `)}
                    </ol>
                `}
            </div>
        `;
    }

    _onInputChange(e){
        if(this._searchTerm === e.target.value) {
            return;
        }

        if (this.inputTimer) {
            clearTimeout(this.inputTimer);
            this.inputTimer = null;
        }

        this.inputTimer = setTimeout(() => {
            this._searchTerm = e.target.value;
            this._search();
        }, 300);
    }

    _resetSearch(){
        this._searchResult=null;
        this._gameMakerSearchResults = null;
        this.requestUpdate();
    }

    _searchForGames(){
        this._loading=true;
        this._searchResult=null;
        this.requestUpdate();
        const searchParam = this._searchTerm.length > 2 ? this._searchTerm : "";

        CAPP_API.getGameDetailLite(searchParam,"all-casino-games", '', '', '', '', '', '', '', 'MostPopular', '', 60, 0, 'en','desktop_game')
            .then(data=>{
                if (!data) {
                    this._loading=false;
                    return;
                }

                this._searchResult=data.games;
                this._searchResult.forEach(item=>{item.loading=true;})
                if(this._searchResult.length > 0 && searchParam.length > 2){
                    this._addToRecentSearches(searchParam);
                }
                this._loading=false;
                this.requestUpdate().then(()=>{
                    this._addNewImagesToObserve();
                })
                GAHelpers.gaEvent('Search','searchResultLoaded');
            });
    }

    _search() {
        if (CAPP_API.searchGameMakersAbortController) {
            CAPP_API.searchGameMakersAbortController.abort();
        }

        if (CAPP_API.getGameDetailLiteAbortController) {
            CAPP_API.getGameDetailLiteAbortController.abort();
        }

        const searchParam = this._searchTerm.length > 2 ? this._searchTerm : "";
        this._loadingGameMakers = true;
        this._gameMakerSearchResults = null;
        this.requestUpdate();

        CAPP_API.searchGameMakers(searchParam)
            .then(response => {
                this._gameMakerSearchResults = response;
                if (!this._gameMakerSearchResults) {
                    this._gameMakerSearchResults = [];
                }
                this._gameMakerSearchResults.forEach(item => item.loading = true);
                this._loadingGameMakers = false;
                this.requestUpdate();

                this._searchForGames();
            });
    }

    _initObserver(){
        if("IntersectionObserver" in window){
            let imageObserver=new IntersectionObserver((entries, observer)=> {
                entries.forEach(entry=> {
                        if (entry.isIntersecting) {
                            this._loadImage(entry.target);
                        }
                    }
                );
            },{
                rootMargin: "0px 300px 300px 0px",
                threshold: 0.0
            });
            this._imageObserver=imageObserver;
        }
    }

    _addNewImagesToObserve(){
        let images=this.renderRoot.querySelectorAll('.game-item a img');
        if(this._imageObserver){
            images.forEach(image=>{
                this._imageObserver.observe(image);
            })
        }else{
            images.forEach(image=>{
                this._loadImage(image);
            })
        }
    }

    _loadImage(image){
        image.onLoad=()=>{
            this._setImageAsLoaded(image);
        };
        if(image.complete){
            this._setImageAsLoaded(image);
        }
        image.src = image.dataset.src;
    }

    _setImageAsLoaded(image){
        image.item.loading=false;
        this._imageObserver.unobserve(image);
        this.requestUpdate();
    }

    _getRecentSearches(){
        let value=window.localStorage.getItem("recentsearch");
        if(value){
            let searches=JSON.parse(value);
            if(searches.length>5){
                searches.splice(0,searches.length-5);
            }

            return searches;
        }else{
            return [];
        }
    }

    _addToRecentSearches(searchTerm){
        if(this._recentSearches.indexOf(searchTerm)<0){
            this._recentSearches.push(searchTerm);
            if(this._recentSearches.length>5){
                this._recentSearches.splice(0,this._recentSearches.length-5);
            }
            window.localStorage.setItem("recentsearch",JSON.stringify(this._recentSearches))
        }
    }

    _onSearchTermClick(e){
        this._searchTerm=e.target.innerText;
        this._search();
    }

    clearSearch(){
        this._searchTerm="";
        this._searchResult=null;
        this._gameMakerSearchResults = null;
        this.requestUpdate();
    }

    _loadPopularSearchTerms(){
        if(SearchSubview.POPULAR_SEARCHES!=null){
            this._populatSearchesLoading=false;
            return;
        }
        CAPP_API.getPopularSearchTerms().then(data=>{
            this._populatSearchesLoading=false;
            SearchSubview.POPULAR_SEARCHES=data=='undefined'?[]:data;
            this.requestUpdate();
        })
    }

    _onGameInfoClick(e){
        this._openGameInfo(e.target.item);
    }

    _openGameInfo(gameItem){
        let gameInfo=new GameInfoSubview(gameItem.gameId, gameItem.gameUrl,'',false,gameItem.sid);
        gameInfo.open();
    }

}

customElements.define('search-sub', SearchSubview);