import {CAPPGameTile} from "./CAPPGameTile";
import {html} from "lit-element";
import {GameInfoSubview} from "./views/GameInfoSubview";
import {Helpers} from "../util/Helpers";
import {CAPP_API} from "../core/CAPPApi";
import {CURRENCY_HELPERS} from "../util/CurrencyHelpers";

export class CAPPJackpotsTile extends CAPPGameTile{
    constructor() {
        super();
        this.mode=CAPPGameTile.MODE.ONE_ROW;
        this._platform=Helpers.getDeviceType();
    }

    _collectGamesData(){
        let gameElements=this.renderRoot.querySelectorAll('.games a');
        let gamesData=[];
        let gameIds=[];
        gameElements.forEach(item=>{
            let gameItem={
                title:item.title,
                gameUrl:item.href,
                gameId:item.dataset.gameId,
                jackpotValue:item.querySelector('span').innerText,
                img:item.querySelector('img').dataset.src,
                loading:true,
                eventLogo: item.dataset.eventLogo ? item.dataset.eventLogo : null
            };
            gameItem.imageBaseName=gameItem.img;
            gameItem.imageBaseName=gameItem.imageBaseName.replace('.jpg','');
            gameItem.imageBaseName=gameItem.imageBaseName.replace('.png','');

            gameIds.push(gameItem.gameId);
            gamesData.push(gameItem);
        })

        this._loadJackpotFeed(gameIds);

        return gamesData;
    }

    _renderGameItems(){
        return html`
            ${this._data.games.map(item=>html`
                <div class="game-item no-title ${item.loading?'loading':''}">
                    <a href="${item.gameUrl}" title="${item.title}" .item=${item} @click=${this._registerPage}>
                        
                        <picture>
                            ${item.isBig!=true ? html`
                                <source data-srcset="${this._getImageUrl(item,'webp','small')}" media="(max-width: 720px)" />
                                <source data-srcset="${this._getImageUrl(item,'jpeg','small')}" media="(max-width: 720px)" />
                                <source data-srcset="${this._getImageUrl(item,'webp','medium')}" media="(min-width: 720px)" />
                                
                            `:``}

                            <img 
                                 data-src="${this._getImageUrl(item,'jpeg','medium')}"   
                                 title="${item.title || ''}"
                                 alt="${item.title + " " + item.category || ''}"
                                 .item=${item}>
                        </picture>
                        
                        
                        ${item.eventLogo && item.eventLogo !== ''?html`
                            <img src="${item.eventLogo}" class="tiles-game-event-logo">
                        `:''}
                    </a>
                    <div class="jackpot-info">
                        <img alt="Jackpot Icon" src="${`${CDN_ASSETS_URL}/icons/jackpot.svg`}">
                        <span>${item.jackpotValue}</span>
                    </div>
                    <div class="game-overlay">
                        ${item.loading?html`
                            <capp-preloader></capp-preloader>
                        `:html`
                            <img class="play-ic" alt="Play Icon" src="${`${CDN_ASSETS_URL}/icons/play.svg`}">
                            ${this._platform!="mobile"?html`
                                <img class="info-ic" alt="Info Icon" src="${`${CDN_ASSETS_URL}/icons/info.svg`}" .item=${item} @click=${this._onGameInfoClick}>
                            `:''}
                            
                        `}
                    </div>
                </div>
            `)}
        `;
    }

    _registerPage() {
        window.sessionStorage.setItem('isNotSingleGamePage', location.href);
    }

    _getImageUrl(item,type,size){
        let w,h;
        switch (size){
            case "small":
                w=126;
                h=84;
                break;
            case "medium":
                w=240;
                h=160;
                break;
            default:
                w=348;
                h=232;

        }
        let url=item.img;
        if (window.isCFCDNEnabled) { url=`${window.CFResizerURL}/width=${w},height=${h},quality=90,format=${type}/${item.img}`; }

        return url;
    }

    _onGameInfoClick(e){
        this._openGameInfo(e.target.item);
    }

    _openGameInfo(gameItem){
        let gameInfo=new GameInfoSubview(gameItem.gameId, gameItem.gameUrl);
        gameInfo.open();
    }


    _loadJackpotFeed(gameIds){

        CAPP_API.loadJackpotFeed(gameIds,window.defaultCurrency).then(data=>{

            if(data.error){
                console.log(data.error);
                return;
            }else{
                try {
                    let jackpotValues={};
                    data.jackpots.forEach(item=>{
                        jackpotValues[item.gameId]=item.amount;
                    })

                    this._data.games.forEach(item=>{
                        if(typeof jackpotValues[item.gameId] != "undefined"){
                            item.amount=jackpotValues[item.gameId];
                        }
                    })
                    this._updateJackpotValues();
                }catch (e){
                    console.log(e);
                }
            }

        })
    }

    _updateJackpotValues(){
        this._data.games.forEach(item=>{
            var add=Math.random()*5;
            item.amount+=add;
            item.jackpotValue=CURRENCY_HELPERS.getCurrencySymbolString(CURRENCY_HELPERS.formatCurrencyWithoutConversion(item.amount));
        })
        this.requestUpdate();

        setTimeout(()=>{
            this._updateJackpotValues();
        },Math.random()*5000+1000);
    }

}

customElements.define('capp-jackpots-tile', CAPPJackpotsTile);