import {SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {USER} from "../../core/User";
import {CAPPProgressBar} from "../CAPPProgressBar";
import {PersonalDetailsSubview} from "./PersonalDetailsSubview";
import {FAST_TRACK} from "../../core/FastTrack";
import {APP_STRINGS} from "../../core/AppStrings";
import {GAHelpers} from "../../util/GAHelpers";
import {CURRENCY_HELPERS} from "../../util/CurrencyHelpers";
import {Helpers} from "../../util/Helpers";
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import { CAPP_API } from "../../core/CAPPApi";
import {PointsInfoSubView} from "./PointsInfoSubView";
//const LANGUAGE=Helpers.getLanguageFromCookie();
const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();

export class ProfileSubView extends SubViewBase{
    constructor() {
        super();
        this._numberOfUnreadMessages=FAST_TRACK.numUnreadMessages;
        console.log('USER',USER);
        this._initEventListeners();

        this._totalPointsProgressPercent=0;
        this._totalPointsGiven=""
        this._nextLevel="";
        this._currentLoyaltyLevel="";
        this._loadLoyaltyProgressData();
    }

    _initEventListeners(){
        FAST_TRACK.on('messagesLoaded',()=>{
            this._numberOfUnreadMessages=FAST_TRACK.numUnreadMessages;
            this.requestUpdate();
        })
    }

    _loadLoyaltyProgressData(){
        CAPP_API.getLoyaltyPointsLevel(USER.sessionKey).then(data=>{
            console.log('_loadLoyaltyProgressData',data);
            try {
                this._totalPointsGiven=parseInt(data.TotalLoyaltyPointsGiven);
                this._nextLevel=parseInt(data.PointsToNextLevel);
                // console.log("_nextLevel 2 = ",this._nextLevel);
                // console.log("_totalPointsGiven 2 = ",this._totalPointsGiven);

                this._totalPointsProgressPercent=Math.min((100/this._nextLevel)*(this._totalPointsGiven),100);
                // console.log("_totalPointsProgressPercent = ",this._totalPointsProgressPercent);
                // console.log("_nextLevel = ",this._nextLevel);
                // console.log("_totalPointsGiven = ",this._totalPointsGiven);

                this._currentLoyaltyLevel=data.CurrentLoyaltyLevel;
                this.requestUpdate();
            }catch (e){}
        })


        USER.on("onUserDataUpdate",()=>{
            this.requestUpdate();
        })

    }

    renderViewContent() {
        return html`
            <div class="profile-sub">
                <div class="profile-header ${this._handlePremiumSectionClass('profileHeader')}">
                    <div class="profile-image">
                        <img alt="Avatar Image" class="${this._handleProfileImageClass()}" src="${`${CDN_ASSETS_URL}/icons/EddyAvatar.png`}">
                        <!--<div class="edit-btn"><img src="${`${CDN_ASSETS_URL}/icons/register.svg`}"></div>-->
                    </div>
                    <h3>${USER.data.Username}</h3>
                    <div class="verified-state">
                        ${USER.data.AccountActivated && USER.data.IsKYCChecked?html`
                            <img alt="KYC Check Icon" src="${`${CDN_ASSETS_URL}/icons/circle-check.svg`}">
                            <span>${APP_STRINGS.getWPMLString('ProfileSubView-verifiedState-verified')}</span>
                        `:html`
                            <img alt="Warning Icon" src="${`${CDN_ASSETS_URL}/icons/warning.svg`}">
                            <span>${APP_STRINGS.getWPMLString('ProfileSubView-verifiedState-unverified')}</span>
                        `}
                        
                    </div>
                    <div class="info">
                        <div class="info-row">
                            <span class="label">${APP_STRINGS.getWPMLString('ProfileSubView-infoField-activeWallet')} ${USER.data.CurrencyISODisplay!=""?`(${USER.data.CurrencyISODisplay})`:''}</span>
                            <span class="value">${USER.data.CurrentBalanceDisaply}</span>
                        </div>
                        <div class="info-row">
                            <span class="label">${APP_STRINGS.getWPMLString('ProfileSubView-infoField-activeBonus')}</span>
                            <span class="value">${USER.data.CurrentBonusBalanceDisaply}</span>
                        </div>
                        <div class="info-row">
                            <span class="label">${APP_STRINGS.getWPMLString('ProfileSubView-infoField-loyaltyLevel')}</span>
                            <span class="value">${USER.data.PlatformVIPLevelName}</span>
                        </div>
                        <div class="info-row">
                            <span class="label">${APP_STRINGS.getWPMLString('ProfileSubView-infoField-points')}</span>
                            <span class="value loyalty-value">
                                <img alt="Loyalty Points Icon" src="${`${CDN_ASSETS_URL}/icons/egg.svg`}">
                                ${USER.data.CurrentLoyaltyPointsDisplay}
                            </span>
                        </div>
                        <div class="info-row">
                            <span class="label">
                                ${APP_STRINGS.getWPMLString('ProfileSubView-infoField-pointsLevel')}
                                <a href="#points">
                                    <img class="points-ic" alt="Emu Points Level Icon" src="${`${CDN_ASSETS_URL}/icons/info.svg`}">
                                </a>
                            </span>
                        </div>
                        <div class="info-row points-value">
                            <span class="label">
                                ${USER.data.VIPLevel > 4 ? html`
                                    <img class="points-img" alt="Emu Points VIP Image" src="${`${CDN_ASSETS_URL}/vip-icon.png`}">
                                ` : html`
                                    <img class="points-img" alt="Emu Points Non-VIP Image" src="${`${CDN_ASSETS_URL}/non-vip-icon.png`}">
                                `}
                                <p>${this._currentLoyaltyLevel}</p>
                            </span>
                            ${this._renderProgressBar()}
                        </div>


                    </div>
                </div>
                <section class="wallet-section ${this._handlePremiumSectionClass('walletSection')}">
                    <h6>${APP_STRINGS.getWPMLString('Wallet-title')}</h6>
                    ${this._noWallet?html`<p class="no-wallet">${unsafeHTML(APP_STRINGS.getWPMLString('noWalletMessage'))}</p>`:''}
                    <div class="wallets-list">
                        <div class="wallet-item active">
                            <div class="info">
                                <span class="currency-icon">${USER.data.CurrencySymbol}</span>
                                <span class="currency">${USER.data.CurrencyISODisplay}</span>
                                <span class="active-icn">${APP_STRINGS.getWPMLString('Wallet-active')}</span>
                            </div>
                            <div class="balance"><span class="cur">${this._renderBalance()}</div>
                        </div>
                    </div>
                    
                </section>
                <section class="bank-links ${this._handlePremiumSectionClass('profileSub')}">
                    <a href="#deposit" class="btn-primary" @click=${this._onDepositClick}>${APP_STRINGS.getWPMLString('ProfileSubView-link-deposit')}</a>
                    <a href="#withdraw" class="btn-secondary">${APP_STRINGS.getWPMLString('ProfileSubView-link-withdraw')}</a>
                </section>
                <section class="links-section ${this._handlePremiumSectionClass('profileSub')}">
                    <a href="#bonuses" class="text-link" @click=${this._onBonusesClick}><span>${APP_STRINGS.getWPMLString('ProfileSubView-link-bonuses')}</span><img src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}"></a>
                    ${this._isDisplayShop()?html`
                    <a href="${`/${LANGUAGE}/emu-shop-page/`}" class="text-link" ><span>${APP_STRINGS.getWPMLString('ProfileSubView-link-shop')}</span><img src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}"></a>
                    `:''}
                    <a href="#inbox" class="text-link"><span>${APP_STRINGS.getWPMLString('ProfileSubView-link-inbox')}</span>
                        ${this._numberOfUnreadMessages>0?html`
                            <span class="c-number">${this._numberOfUnreadMessages}</span><img src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}">
                        `:html`
                            <img alt="Right Chevron Icon" src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}">
                        `}
                    </a>
                    <a href="#personal-details" class="text-link"><span>${APP_STRINGS.getWPMLString('ProfileSubView-link-personalDetails')}</span><img alt="Right Chevron Icon" src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}" ></a>
                    <a href="#verification" class="text-link"><span>${APP_STRINGS.getWPMLString('ProfileSubView-link-verification')}</span><img alt="Right Chevron Icon" src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}"></a>
                    <a href="#history" class="text-link"><span>${APP_STRINGS.getWPMLString('ProfileSubView-link-history')}</span><img alt="Right Chevron Icon" src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}"></a>
                    <a href="#limits" class="text-link"><span>${APP_STRINGS.getWPMLString('ProfileSubView-link-limits')}</span><img alt="Right Chevron Icon" src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}"></a>
                </section>
                <div class="btn-wrap">
                    <button class="btn-secondary" @click=${this._onLogoutClick}><img alt="Logout Button Icon" src="${`${CDN_ASSETS_URL}/icons/lock-alt.svg`}">${APP_STRINGS.getWPMLString('ProfileSubView-link-logOut')}</button>
                </div>
                
            </div>
            <button class="close-btn ${this._handlePremiumSectionClass('profileHeader')}" @click=${this._onCloseButtonCLick}><img alt="Close Button Icon" class="close-btn-icn" src="${`${CDN_ASSETS_URL}/icons/close.svg`}"></button>
        `;
    }

    _renderBalance(){
        if(CURRENCY_HELPERS.getCurrencySymbolPlacement()=='left'){
            return html`
                <span class="cur">${USER.data.CurrencySymbol}</span>${USER.data.CurrentBalanceNumberDisaply}
            `;
        }else{
            return html`
                ${USER.data.CurrentBalanceNumberDisaply}<span class="cur">${CURRENCY_HELPERS.getRightAlignedCurrencySymbolPrefix()}${USER.data.CurrencySymbol}</span>
            `;
        }
    }

    _onCloseButtonCLick(){
        location.href="#";
    }

    _onLogoutClick(){
        USER.logout();
        location.href="#";
        
        let ps=document.querySelector("capp-navigation");
        ps.classList.remove("premium-section");

        let scoreTableLists=document.querySelector('.score-table-lists');
        if(scoreTableLists !== null){
            scoreTableLists.classList.remove("player-position");
        }
    }

    _onBonusesClick(){
        GAHelpers.gaEvent('ProfileSubView','bonusesClick');
    }

    _onDepositClick(){
        GAHelpers.gaEvent('ProfileSubView','depositClick');
    }

    _isDisplayShop(){
        let display = true;

        if(CURRENCY_HELPERS.isNonStableCrypto){
            display = false;
        }
        return display;
    }

    _handleProfileImageClass(){
        switch (USER.data.VIPLevelID){
            case 7:
                return 'gold-profile-image'
                break;
            case 5:
                return 'vip-profile-image'
                break;
            case 6:
                return 'vvip-profile-image'
                break;
            default:
                return ''
                break;
        }
    }

    _handlePremiumSectionClass(section){
        if (USER.data.VIPLevelID > 4){ //level name is gold, vip and vvip

            switch(section) {
                case 'walletSection':
                    return 'wallet-premium-section premium-section'

                case 'profileHeader':
                    return 'profile-premium-section'

                case 'profileSub':
                    return 'premium-section'

            }
        }
        return ''
    }

    _renderProgressBar(){
        return html`
            <capp-progress-bar class="points-pb" value="${this._totalPointsProgressPercent}" layout="slim"></capp-progress-bar>
            <span></span>
            <div class="progress-labels points-label">
                <span>${this._totalPointsGiven} / ${this._nextLevel}</span>
            </div>
            `;
    }
}

customElements.define('profile-sub',ProfileSubView);