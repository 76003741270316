import {LitElement, html} from "lit-element";
import {Helpers} from "../util/Helpers";
import {CURRENCY_HELPERS} from "../util/CurrencyHelpers";
import {USER} from "../core/User";
import {SearchSubview} from "./views/SearchSubview";
import {LoginSubview} from "./views/LoginSubview";
import {ProfileSubView} from "./views/ProfileSubView";
import {RegistrationSubview} from "./views/RegistrationSubview";
import {MenuSubview} from "./views/MenuSubview";
import {LanguageMenuSubview} from "./views/LanguageMenuSubview";
import {APP_STRINGS} from "../core/AppStrings";
import {FAST_TRACK} from "../core/FastTrack";

//const LANGUAGE=Helpers.getLanguageFromCookie();
const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();
window.userLogout=function(){
    USER.logout();
}

export class CAPPNavigation extends LitElement{
    constructor() {
        super();

        this.navigationData={
            logo:'',
            navigationItems:[],
            languageSubNavigationItems:[],
        }
        this._numberOfUnreadMessages=FAST_TRACK.numUnreadMessages;
        this._initEventListeners();
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        let logo=this.renderRoot.querySelector('#logo');
        this.navigationData.logo=logo.src;

        let logoSmall=this.renderRoot.querySelector('#logo-small');
        this.navigationData.logoSmall=logoSmall.src;

        let nav=this.renderRoot.querySelectorAll('#main-navigation a');
        nav.forEach(item=>{
            let span=item.querySelector('span');
            if(span){
                let iconImg=item.querySelector('img');
                let navItem={
                    link:item.href,
                    label:span.innerHTML,
                    active:item.dataset.active=='true'?true:false,
                    icon: iconImg ? iconImg.src:""
                };
                this.navigationData.navigationItems.push(navItem);
            }
        })

        let subNav=this.renderRoot.querySelectorAll('#language-sub-navigation a');
        subNav.forEach(item=>{
            let span=item.querySelector('span');
            if(span){
                let iconImg=item.querySelector('img');
                let navItem={
                    link:item.href,
                    label:span.innerHTML,
                    active:item.dataset.active=='true'?true:false,
                    icon: iconImg ? iconImg.src:""
                };
                this.navigationData.languageSubNavigationItems.push(navItem);
            }
            
        })

        
        console.log('NAVIGATION DATA',this.navigationData);
        super.connectedCallback();
        this._handlePremiumSectionClass();

    }

    render(){
        return html`
            <div class="l-wrap">
                <button class="menu-btn" data-action="menu" @click=${this._handleButtonClick}><img alt="Menu Button Icon" src="${`${CDN_ASSETS_URL}/icons/menu-bar.svg`}" width="24" height="24"></button>
                <a class="logo small" href="/${LANGUAGE}/"><img alt="Small Site Logo" src="${this.navigationData.logoSmall}" width="27" height="28" ></a>
                <a class="logo" href="/${LANGUAGE}/"><img alt="Site Logo" src="${this.navigationData.logo}" width="205" height="32"></a>
            </div>
            
            <nav>
                ${this.navigationData.navigationItems.map(item=>
                        html`<a class="${item.active?'active':''}" href="${item.link}">
                            <img alt="${item.label} Icon" class="icon" src="${item.icon}" width="20" height="20">
                            <span>${item.label}</span>
                        </a>`
                )}
            </nav>
            ${this._renderHeaderControls()}
        `;
    }

    _renderHeaderControls(){
        let selectedLanguageIcon=this._getSelectedLanguageIcon(this.navigationData.languageSubNavigationItems);
    
        return html`
            <div id="header-controls">
                <button class="search" data-action="search" @click=${this._handleButtonClick}>
                    <img alt="Search Button Icon" class="icon" src="${`${CDN_ASSETS_URL}/icons/search.svg`}" width="20" height="20">
                    <span>${APP_STRINGS.getWPMLString('NavigationComponent-search', 'Search')}</span>
                </button>
                ${this.navigationData.languageSubNavigationItems.length>0? 
                html`<button class="language" data-action="language" @click=${this._handleButtonClick}>
                <img alt="language Button Icon" class="icon ${selectedLanguageIcon.isDefaultIcon==false?'language-flag':''}" src="${selectedLanguageIcon.icon}" width="20" height="20">
            
               </button>`:''}
                ${USER.isLoggedIn?
                html`
                    <a class="header-ft-inbox" href="#inbox">
                        <span>${this._numberOfUnreadMessages}</span>
                    </a>
                    <button class="hc-btn-2 points" data-action="deposit" title="Deposit" @click=${this._handleButtonClick}>
                        <img alt="Wallet Icon" class="icon" src="${`${CDN_ASSETS_URL}/icons/wallet.svg`}" width="20" height="20">
                        <span>${APP_STRINGS.getWPMLString('NavigationComponent-Deposit', 'Deposit')}</span>
                    </button>
                    <button class="hc-btn-1 balance" data-action="account" title="Account" @click=${this._handleButtonClick}>
                        <img alt="User Icon" class="icon" src="${`${CDN_ASSETS_URL}/icons/user.svg`}" width="20" height="20">
                        <span>${USER.data.CurrentBalanceDisaply}</span>
                    </button>
                `:html`
                    <button class="hc-btn-1 sign-up" data-action="signUp" @click=${this._handleButtonClick}>
                        <img alt="Sign Up Button Icon" class="icon" src="${`${CDN_ASSETS_URL}/icons/register.svg`}" width="20" height="20">
                        <span>${APP_STRINGS.getWPMLString('NavigationComponent-signUp', 'Sign Up')}</span>
                    </button>
                    <button class="hc-btn-2 login" data-action="login" @click=${this._handleButtonClick}>
                        <img alt="Login Button Icon" class="icon" src="${`${CDN_ASSETS_URL}/icons/login.svg`}" width="20" height="20">
                        <span>${APP_STRINGS.getWPMLString('NavigationComponent-login', 'Log In')}</span>
                    </button>
                `}
            </div>
        `;
    }

    _initEventListeners(){
        USER.addListener("onLoginStateChange",e=>{
            this.requestUpdate();
        });

        USER.addListener("onUserBalanceUpdate",()=>{
            this.requestUpdate();
        });

        APP_STRINGS.on("WPML_Ready",()=>{
            this.requestUpdate();
        })

        FAST_TRACK.on('messagesLoaded',()=>{
            this._numberOfUnreadMessages=FAST_TRACK.numUnreadMessages;
            this.requestUpdate();
        })
    }

    _handleButtonClick(e){
        console.log('do action',e.currentTarget.dataset.action);
        //todo implement launch actions
        switch (e.currentTarget.dataset.action){
            case 'menu':
                console.log('on menu button click');
                let menu=new MenuSubview(this.navigationData.navigationItems,this.navigationData.languageSubNavigationItems);
                menu.open();
                break;
            case 'search':
                let search=new SearchSubview();
                search.open();
                break;
            case 'account':
                location.href="#profile";
                break;
            case 'deposit':
                location.href="#deposit";
                break;
            case 'signUp':
                location.href="#signup";
                break;
            case 'login':
                location.href="#login";
                break;
            case 'shop':
                location.href="/"+LANGUAGE+"/emu-shop-page/";
                let l=`/${LANGUAGE}/emu-shop-page/`;
                break;
            case 'language':
                let languageMenu = new LanguageMenuSubview(this.navigationData.languageSubNavigationItems,this.navigationData.navigationItems,'right');
                languageMenu.open();
                break;               

        }
    }

    _handlePremiumSectionClass(){
        let ps=document.querySelector("capp-navigation");
        if (USER.isLoggedIn) {
            if (USER.data.VIPLevel >= 4){ //level name is gold, vip and vvip

                ps.classList.add("premium-section");
            }
        }
    }

    _getSelectedLanguage(languageItems){
       
        return languageItems.find(function (languageItem){
           
            if(languageItem.active == true){
               return languageItem;
            }
           
        });
       
    }

    _getSelectedLanguageIcon(languageItems){
       
       let selectedLanguage = this._getSelectedLanguage(languageItems);;
       let isDefaultIcon=true;
       if(selectedLanguage!==undefined){
         if(selectedLanguage.link.match("/en/")){
             selectedLanguageIcon=CDN_ASSETS_URL+'/icons/language.svg';
         
         }else{
             selectedLanguageIcon= selectedLanguage.icon;
             isDefaultIcon=false;
         }
       }else{
         selectedLanguageIcon= CDN_ASSETS_URL+'/icons/language.svg';
       
       }

       let selectedLanguageIcon={
        icon:selectedLanguageIcon,
        isDefaultIcon:isDefaultIcon,
       
       };
        return selectedLanguageIcon;
    }
}

customElements.define('capp-navigation', CAPPNavigation);