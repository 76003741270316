import {LitElement, html} from "lit-element";
import {Helpers} from "../util/Helpers";
import {APP_STRINGS} from "../core/AppStrings";

export class CAPPCookieAccept extends LitElement{
    constructor() {
        super();
        document.body.appendChild(this);
    }

    createRenderRoot() {
        return this;
    }

    render(){
        return html`
            <div class="ac-inner">
                <p>${APP_STRINGS.getWPMLString('CookieAccept-message')}</p>
            </div>
            <img alt="Accept Cookies Button Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}" @click=${this._acceptCookies}>
        `
    }


    _acceptCookies(){
        document.body.removeChild(this);
        Helpers.setCookieWithSeconds('cookiesAccept', "true", 315360000);
    }
}

customElements.define('capp-cookie-accept',CAPPCookieAccept);