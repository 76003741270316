import {POPUP_DESKTOP_ALIGN, POPUP_MOBILE_ALIGN, PopupBase} from "./PopupBase";
import {html} from "lit-element";
import {unsafeHTML} from "lit-html/directives/unsafe-html";

export class ShoutoutPopup extends PopupBase{
    constructor(notification) {
        super(POPUP_MOBILE_ALIGN.TOP,POPUP_DESKTOP_ALIGN.CENTER);
        this._notification=notification;
        console.log('ShoutoutPopup',this._notification);
    }

    _renderContent(){
        return html`
            <div class="shoutout-wrap">
                <div class="so-header">
                    <h3>${unsafeHTML(this._notification.Title)}</h3>
                    <img class="close-btn" alt="Close Button Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}" @click=${this._onCloseButtonClick}>
                </div>
                
                ${this._notification.ImageUrl!=""?html`
                        <img class="message-img" alt="Notification Image" src="${this._notification.ImageUrl}">
                    `:''}
                <div class="message-wrap">${unsafeHTML(this._notification.Message)}</div>
                <div class="buttons-wrap">
                    ${this._notification.CTAButtonText!=""?html`
                        <a class="btn-primary" @click=${this._onCloseButtonClick} href="${this._notification.CTAButtonLink}">${this._notification.CTAButtonText}</a>
                    `:''}
                    ${this._notification.CTAButton2Text!=""?html`
                        <a class="btn-primary" @click=${this._onCloseButtonClick} href="${this._notification.CTAButton2Link}">${this._notification.CTAButton2Text}</a>
                    `:''}
                </div>
            </div>
        `
    }

    _onCloseButtonClick(){
        this._close();
    }
}

customElements.define('shoutout-popup',ShoutoutPopup);