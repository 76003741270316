import {SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import {APP_STRINGS} from "../../core/AppStrings";


export class PointsInfoSubView extends SubViewBase{
    constructor() {
        super();
    }

    renderViewContent() {
        return html`
            <div class="points-info-sub-wrap">
                <div class="ri-header">
                    <div class="controls">
                        <img class="close" alt="Close Button Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}" @click=${this._onCloseButtonClick}>
                    </div>
                    <h3>${APP_STRINGS.getWPMLString('HowToLevelUpSubView-title')}</h3>
                </div>
                <div class="content">
                    <section>
                        <img alt="Spin Icon" src="${`${CDN_ASSETS_URL}/icons/spin.svg`}">
                        <h4>${APP_STRINGS.getWPMLString('HowToLevelUpSubView-step1-title')}</h4>
                        <div class="text">${unsafeHTML(APP_STRINGS.getWPMLString('HowToLevelUpSubView-step1-content'))}</div>
                    </section>
                    <section>
                        <img alt="Points Icon" src="${`${CDN_ASSETS_URL}/icons/points.svg`}">
                        <h4>${APP_STRINGS.getWPMLString('HowToLevelUpSubView-step2-title')}</h4>
                        <div class="text">${unsafeHTML(APP_STRINGS.getWPMLString('HowToLevelUpSubView-step2-content'))}</div>
                    </section>
                    <section>
                        <img alt="Rewards Icon" src="${`${CDN_ASSETS_URL}/icons/rewards.svg`}">
                        <h4>${APP_STRINGS.getWPMLString('HowToLevelUpSubView-step3-title')}</h4>
                        <div class="text">${unsafeHTML(APP_STRINGS.getWPMLString('HowToLevelUpSubView-step3-content'))}</div>
                    </section>
                    <div class="btn-wrap">
                        <button class="btn-secondary" @click=${this._onMoreInfoClick}>${APP_STRINGS.getWPMLString('ProfileSubView-moreInfo')}</button>
                    </div>
                </div>
            </div>
        `;
    }

    _onCloseButtonClick(){
        window.history.back();
    }

    _onMoreInfoClick(){
        location.href="/promotions/emupoints-and-emushop/achievements-and-levels/";
    }


}

customElements.define("points-info-sub",PointsInfoSubView);

window.showRI=function (){
    let r=new PointsInfoSubView();
    r.open();
}