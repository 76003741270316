import {LitElement, html} from "lit-element";

export class CAPPCountdown extends LitElement{

    constructor() {
        super();
        this.countdownID=null;
        this.countdownDate='';
    }

    createRenderRoot() {
        return this;
    }

    firstUpdated(changedProperties) {
        console.log('Start the countdown timer for ' + this.countdownDate);
        console.log('firstUpdated', changedProperties);

        let countdown = this.renderRoot.querySelector('.capp-countdown');

        countdown.style.display = 'none';
        let els = countdown.innerText;
        var countDownDate = new Date(els).getTime();

        console.log('firstUpdated - Countdown Date', els);

        // Update the countdown every 1 second
        var x = setInterval(function() {
            // Get the current date and time
            let now = new Date().getTime();

            // Calculate the distance between now and the count down date
            let distance = countDownDate - now;

            // Calculate days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element
            countdown.style.display = 'block';
            countdown.innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";

            // If the count down is finished, display a message
            if (distance < 0) {
                clearInterval(x);
                countdown.innerHTML = "00d 00h 00m 00s";
            }

        }, 1000);
        super.firstUpdated(changedProperties);
    }

    connectedCallback() {
        console.log('Set the countdown timer for ' + this.renderRoot.innerHTML);

        if(this.renderRoot.id){
            this.countdownID = 'CAPPCountdown_'+this.renderRoot.id;
        }

        this.countdownDate = this.renderRoot.innerHTML;
        console.log(this.countdownDate);
        super.connectedCallback();
    }

    render(){
        console.log('Render the countdown timer for ' + this.countdownDate);
        return html`
            ${this.countdownID?html`
                <div id="${this.countdownID}" class="capp-countdown">${this.countdownDate}</div>
            `:html`
                <div class="capp-countdown">${this.countdownDate}</div>
            `}
        `;
    }
}

customElements.define('capp-countdown',CAPPCountdown);