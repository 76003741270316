import {LitElement, html} from "lit-element";

export class CAPPAccordion extends LitElement{

    static MODE = {
        SINGLE:'single',
        MULTIPLE:'multiple'
    }

    static get properties() {
        return {
            mode: {type:String},
            cadd: {type:Number},
            expandIcon: {type:String,attribute:'expand-icon'},
            collapseIcon: {type:String,attribute:'collapse-icon'}
        };
    }

    constructor() {
        super();

        this.titleElements=[];
        this.contentElements=[];
        this.expanded=[];
        this.contentHeight=[];
        this.collectedContent = false;

    }

    createRenderRoot() {
        return this;
    }

    firstUpdated(changedProperties) {
        this._chAdd=this.cadd?this.cadd:0;

        setTimeout(()=>{
            this._updateContentMeasurements();
            this.requestUpdate();
        },10)

        super.firstUpdated(changedProperties);
    }

    connectedCallback() {

        if (this.collectedContent) {
            return;
        }
        this.collectedContent = true;
        for (let i = 0; i < this.renderRoot.children.length; i++) {
            let ce=this.renderRoot.children[i];
            if(ce.classList.contains('title')) {
                this.titleElements.push(ce.innerText);
                this.expanded.push(ce.classList.contains('expanded'));
            }else if(ce.classList.contains('content')){
                let elIndex=this.titleElements.length-1;
                this.contentElements[elIndex]=ce;
                this.contentHeight[elIndex]=ce.clientHeight;
            }
        }

        window.addEventListener('resize',()=>{
            this._updateContentMeasurements();
            this.requestUpdate();
        })

       super.connectedCallback();
    }



    render(){
        return html`
            ${this.titleElements.map((el,index)=>html`
                <div class="title ${this.expanded[index]?'expanded':''}" .index=${index} @click=${this._onAccTitleClick}>
                    <span>${this.titleElements[index]}</span>
                    ${this.expanded[index]?html`
                        ${this._renderCollapseIcon()}
                    `:html`
                        ${this._renderExpandIcon()}
                    `}
                    
                </div>
                <div class="acc-wrap" style="height: ${this.expanded[index]?`${this.contentHeight[index]+this._chAdd}px`:'0px'}">${this.contentElements[index]?this.contentElements[index]:''}</div>
            `)}
        `;
    }

    _renderCollapseIcon(){
        if(this.collapseIcon){
            return html`<img alt="Collapse Icon" src="${this.collapseIcon}">`;
        }else{
            return html`<img alt="Collapse Icon" src="${`${CDN_ASSETS_URL}/icons/up-chevron.svg`}">`;
        }
    }

    _renderExpandIcon(){
        if(this.expandIcon){
            return html`<img alt="Expand Icon" src="${this.expandIcon}">`;
        }else{
            return html`<img alt="Expand Icon" src="${`${CDN_ASSETS_URL}/icons/down-chevron.svg`}">`;
        }
    }

    _updateContentMeasurements(){
        //let contentElements=this.renderRoot.querySelectorAll('.acc-wrap .content');
        for(let i=0; i<this.contentElements.length; i++){
            if(this.contentElements[i]){
                this.contentHeight[i]=this.contentElements[i].clientHeight;
            }else{
                this.contentHeight[i]=50;
            }

        }
    }

    _onAccTitleClick(e){
        e.preventDefault();
        this._updateContentMeasurements();
        let index=e.currentTarget.index;

        this.expanded[index]=!this.expanded[index];

        if(this.mode != CAPPAccordion.MODE.MULTIPLE && this.expanded[index]){
            for(let i=0; i<this.expanded.length; i++){
                if(i!=index){
                    this.expanded[i]=false;
                }
            }
        }

        this.requestUpdate().then(()=>{
            this._dispatchChangeEvent();
        });

    }

    _dispatchChangeEvent(){
        const event = new CustomEvent('change', {});
        this.dispatchEvent(event);
    }



}

customElements.define('capp-accordion',CAPPAccordion);

