import {CAPPInput} from "./CAPPInput";
import {html} from "lit-element";
import {USER} from "../../core/User";
import {Helpers} from "../../util/Helpers";
import {CURRENCY_HELPERS} from "../../util/CurrencyHelpers";

export class CAPPAmountInput extends CAPPInput{

    static INPUT_MODE = {
        FLOAT:'decimal',
        INTEGER:'numeric'
    }

    static get properties() {
        return {
            inputMode: {type:String},
        };
    }
    constructor() {
        super();
        this.type=CAPPInput.INPUT_TYPE.NUMBER;
        this.inputMode=CAPPAmountInput.INPUT_MODE.FLOAT;
        this._applyPlaceholderValue();
    }

    connectedCallback() {
        this._applyPlaceholderValue();
        super.connectedCallback();
    }

    firstUpdated(changedProperties) {
        this._inputEl=this.renderRoot.querySelector('.main-input');
        this._displayEl=this.renderRoot.querySelector('.amount-display');
        this._inputEl.addEventListener('change',e=>{
            e.stopPropagation();
            e.preventDefault();
            if(this._inputEl.value ==''){
                this._value=0;
            }else{
                this._value=this._inputEl.value;
            }
            this._formatCurrencyDisplay();
            this._dispatchChangeEvent();
        })
        this._inputEl.addEventListener('keyup',e=>{
            e.stopPropagation();
            e.preventDefault();
            this._value=this._inputEl.value;
            this._formatCurrencyDisplay();
            this._dispatchKeyupEvent(e);
        })

        this._inputEl.addEventListener('keydown',e=>{
            //if input mode is integer
            if(this.inputMode==CAPPAmountInput.INPUT_MODE.INTEGER) {
                //valid input is only numbers, backspace, delete, and arrow keys
               let validCharacter= (e.which > 47 && e.which < 58) || (e.which >= 96 && e.which <= 105) || e.which == 37 || e.which == 39 || e.which == 8 || e.which == 46;
               if(!validCharacter) {
                   e.preventDefault();
               }
            }
        })
    }

    set value(val){
        if(this.inputMode==CAPPAmountInput.INPUT_MODE.INTEGER) {
            this._value=parseInt(val);
        }else{
            this._value=val;
        }

        this._formatCurrencyDisplay();
        this.requestUpdate();
    }

    _applyPlaceholderValue(){
        if(this.inputMode==CAPPAmountInput.INPUT_MODE.INTEGER) {
            this.placeholder='0';
        }else{
            this.placeholder='0.00';
        }
    }

    _formatCurrencyDisplay(){
        if(this.inputMode==CAPPAmountInput.INPUT_MODE.INTEGER) {
            this._displayEl.value=CURRENCY_HELPERS._formatIntegerCurrency(this._value);
        }else{
            if(this._value=="") {this._value =0};
            this._displayEl.value=CURRENCY_HELPERS.formatCurrencyWithoutConversion(this._value);
        }
    }

    get value(){
        let val=parseFloat(this._value);
        if(this.inputMode==CAPPAmountInput.INPUT_MODE.INTEGER) {
            val=parseInt(this._value);
        }else{
            val=parseFloat(this._value);
        }
        return val;
    }

    _renderInput() {

        let icons=this._getIcons();
        console.log(icons);

        return html`
            <div class="input-wrap">
                <input
                    placeholder="${this.placeholder?this.placeholder:''}" 
                    spellcheck="false" 
                    .value="${this._value}"
                    type="${CAPPInput.INPUT_TYPE.NUMBER}"
                    autocapitalize="none"
                    inputmode="${this.inputMode}"
                    class="main-input ${icons.length>0?`pi-${icons.length}`:''}"
                    ?disabled=${this.disabled}
                    name="${this.name}"
                >
                <input class="amount-display" readonly tabindex="-1" placeholder="${this.placeholder?this.placeholder:''}" ></input>
                <div class="icons">
                    ${icons}
                </div>
                <span class="currency-icon">${USER.data.CurrencySymbol}</span>                
            </div>
        `;
    }
}

customElements.define('capp-amount-input', CAPPAmountInput);