import {LitElement,html} from "lit-element";

export class CAPPProgressBar extends LitElement{
    static LAYOUT_TYPE = {
        TOP:'top',
        BOTTOM:'bottom',
        SLIM:'slim'
    }

    static get properties() {
        return {
            value: {type:Number},
            layout: {type:String}
        };
    }

    constructor() {
        super();
    }

    createRenderRoot() {
        return this;
    }

    render(){
        let layoutType=this.layout?this.layout:CAPPProgressBar.LAYOUT_TYPE.TOP;
        return html`
            ${layoutType==CAPPProgressBar.LAYOUT_TYPE.BOTTOM?this._renderProgressNumbers():''}
            <div class="progress-wrap">
                <div class="progress-bar" style="width: ${this.value}%"></div>
            </div>
            ${layoutType==CAPPProgressBar.LAYOUT_TYPE.TOP?this._renderProgressNumbers():''}
        `;
    }

    _renderProgressNumbers(){
        return html`
            <div class="values-wrap">
                <span>${this.value}%</span>
                <span>100%</span>
            </div>
        `;

    }
}

customElements.define('capp-progress-bar', CAPPProgressBar);