import {html} from "lit-element";
import {USER} from "../../core/User";
import {POPUP_DESKTOP_ALIGN, POPUP_MOBILE_ALIGN, PopupBase} from "./PopupBase";
import {CAPPPopup} from "./CAPPPopup";
import {APP_STRINGS} from "../../core/AppStrings";

export class AccountActivationProcessorPopup extends PopupBase{
    constructor(activationCode,ea) {
        super(POPUP_MOBILE_ALIGN.TOP,POPUP_DESKTOP_ALIGN.CENTER);
        this._processing=true;
        this._processActivation(activationCode,ea);
    }

    _processActivation(activationCode,ea){
        USER.activateAccount(activationCode,ea).then(data=>{
            this._close();
            window.history.pushState({}, document.title, window.location.pathname);
            if(data.error){
                new CAPPPopup(
                    APP_STRINGS.getWPMLString('Common-Error'),
                    data.error.text,
                    [{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-secondary'}]
                )
            }else{
                new CAPPPopup(
                    APP_STRINGS.getWPMLString('accountActivationSuccessPopup-title'),
                    APP_STRINGS.getWPMLString('accountActivationSuccessPopup-message'),
                    [{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-secondary'}]
                )
                if(USER.isLoggedIn){
                    USER.updateUserData();
                }
            }

        })
    }

    _renderContent(){
        return html`
            <div class="bonus-claim-popup-content">
                ${this._renderProcessingLayout()}
            </div>
        `;
    }

    _renderProcessingLayout(){
        return html`
            <div class="processing">
                <span>Activating Your Account...</span>
                <capp-preloader></capp-preloader>
            </div>
        `;
    }
}

customElements.define('account-activation-processor-popup',AccountActivationProcessorPopup);