import {POPUP_DESKTOP_ALIGN, POPUP_MOBILE_ALIGN, PopupBase} from "./PopupBase";
import {html} from "lit-element";
import {USER} from "../../core/User";
import {Validation} from "../../util/Validation";
import {FormHelpers} from "../../util/FormHelpers";
import {CAPPPopup} from "./CAPPPopup";
import {Helpers} from "../../util/Helpers";
import {APP_STRINGS} from "../../core/AppStrings";

const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();

export class NewPasswordPopup extends PopupBase{
    constructor(userEmail,temporaryPassword,sessionKey,skipPasswordChecking=false) {
        super(POPUP_MOBILE_ALIGN.TOP,POPUP_DESKTOP_ALIGN.CENTER);
        this._email=userEmail;
        this._tempSessionKey=sessionKey;
        this._tempPassword= temporaryPassword;
        this._skipPasswordChecking=skipPasswordChecking;

        this._passwordValidationData={
            capitalLetterCheck: false,
            lengthCheck: false,
            numberCheck: false,
            passwordStrengthLabel:'',
            passwordStrength:0
        }
        this._loading=false;
    }

    _renderContent(){
        return html`
            <div class="popup-wrap">
                <h4>${APP_STRINGS.getWPMLString('newPasswordPopup-title')}</h4>
                <div class="popup-body">
                    <form class="reg-form" @submit=${this._onNewPasswordSubmit}>
                        <div class="password-group">
                            <capp-input label="${APP_STRINGS.getWPMLString('newPasswordPopup-inputLabel')}" name="password" autocomplete="off" type="password" @change=${this._handleInputChange} @keyup=${this._handleInputChange}></capp-input>
                            ${this._renderPasswordValidations()}
                        </div>
                        <button class="btn-primary ${this._loading?'loading':''}" ?disabled=${!this._passwordValidationData.valid} type="submit">${APP_STRINGS.getWPMLString('newPasswordPopup-submitButton')}</button>
                    </form>
                </div>
            </div>
        `;
    }

    _renderPasswordValidations(){
        return html`
            <div class="vcat">
                <div>${this._renderValidationIcon(this._passwordValidationData.lengthCheck)}<span>Min. 8 characters</span></div>
                <div>${this._renderValidationIcon(this._passwordValidationData.capitalLetterCheck)}<span>1 capital letter</span></div>
                <div>${this._renderValidationIcon(this._passwordValidationData.numberCheck)}<span>1 numeric</span></div>
            </div>
            <capp-progress-bar value="${this._passwordValidationData.passwordStrength}" layout="slim"></capp-progress-bar>
            <div class="pw-strength"><span class="label">Weak</span><span class="value">Strong</span></div>
            
        `;
    }

    _renderValidationIcon(value){
        if(value){
            return html`<img class="valid" alt="Valid Input Icon" src="${`${CDN_ASSETS_URL}/icons/single-check.svg`}">`;
        }else{
            return html`<img alt="Invalid Input Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}">`;
        }
    }

    _onNewPasswordSubmit(e){
        e.preventDefault();
        if(this._skipPasswordChecking ==false){
            if(this._validatePassword()!=true){
                return;
            }

        }

      
        USER.sessionKey=this._tempSessionKey;

        let formData=FormHelpers.getFormDataAsObject(e.target);
        this._loading=true;
        this.requestUpdate();

        USER.changePassword(this._tempPassword,formData.password).then(data=>{
            this._close();
            if(data.error){
                new CAPPPopup(
                    APP_STRINGS.getWPMLString('Common-Error'),
                    data.error.text,
                    [{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-secondary'}]
                )
            }else{

                let p=new CAPPPopup(
                    APP_STRINGS.getWPMLString('newPasswordPopup-successPopup-title'),
                    APP_STRINGS.getWPMLString('newPasswordPopup-successPopup-message'),
                    [{label:APP_STRINGS.getWPMLString('newPasswordPopup-successPopup-loginBtn'),type:'btn-primary'}],
                    (btnId)=>{
                        USER.login(this._email, formData.password,'/'+LANGUAGE+'/');
                        p.renderRoot.querySelector('.btn-primary').classList.add('loading');
                    }
                )
                p.useManualClose=true;


            }
        })
    }

    _handleInputChange(){
        this._validatePassword();
    }

    _validatePassword(){
        let valid=true;
        let r;
        let inputEl=this.renderRoot.querySelector('capp-input[name="password"]');

        r=Validation.validatePassword(inputEl.value);
        valid=r.valid;
        if(r.valid){
            inputEl.valid=true;
        }else{
            inputEl.valid=false;
        }
        inputEl.message=null;
        this._passwordValidationData=r;
        this.requestUpdate();
        return valid;
    }


}
customElements.define('new-password-popup',NewPasswordPopup);