import {html} from "lit-element";
import {SubViewBase} from "./SubViewBase";
import {USER} from "../../core/User";
import {APP_STRINGS} from "../../core/AppStrings";
import {ShowChatWindow} from "../../controllers/LivechatController";
import {unsafeHTML} from "lit-html/directives/unsafe-html";

export class DepositResultView extends SubViewBase{
    constructor(resultType) {
        super();
        console.log('deposit results view');
        this._resultType=resultType;

        let url=new URL(window.location.href);
        let bonusId=url.searchParams.get("bonusID");
        this._redirectToBonuses=this._resultType=='success' && bonusId && bonusId!='';

        this._cardDescriptor = url.searchParams.get("cardDescriptor");

        window.history.replaceState({}, document.title, window.location.pathname);
        this.open();
    }


    renderViewContent() {
        return html`
            <div class="deposit-sub std-sub-grid ${this._loading?'loading':''}">
                ${this._resultType=='success'?this._renderSuccessView():''}
                ${this._resultType=='pending'?this._renderPendingView():''}
                ${this._resultType=='fail'?this._renderDepositFailedView():''}
            </div>
        `;
    }

    _renderSuccessView(){
        return html`
            <div class="success-view">
                <img class="success-icn" alt="Success Icon" src="${`${CDN_ASSETS_URL}/icons/circle-check.svg`}">
                <h3>${APP_STRINGS.getWPMLString('DepositSubView-success-title')}</h3>
                ${this._cardDescriptor?html`<p>${this._cardDescriptor}</p>`:''}
                <p>${APP_STRINGS.getWPMLString('DepositSubView-success-message')}</p>
                <p>${APP_STRINGS.getWPMLString('DepositSubView-success-newBalance')}<br>
                    <span class="balance">${USER.data.CurrentBalanceDisaply}</span>
                </p>
                <p class="smaller">${unsafeHTML(APP_STRINGS.getWPMLString('DepositSubView-success-smallWithdrawMessage'))}</p>
                <a class="btn-secondary continue-btn" @click=${this._onBackButtonClick}>${APP_STRINGS.getWPMLString('DepositSubView-success-continue')}</a>
            </div>
        `;
    }



    _renderPendingView(){
        return html`
            <div class="success-view">
                <img class="success-icn" alt="Success Icon" src="${`${CDN_ASSETS_URL}/icons/circle-check.svg`}">
                <h3>${APP_STRINGS.getWPMLString('DepositSubView-pending-title')}</h3>

                ${this._cardDescriptor?html`
                    <p>${this._cardDescriptor}</p>
                    <p>${unsafeHTML(APP_STRINGS.getWPMLString('DepositSubView-pending-message2'))}</p>
                `:html`
                    <p>${unsafeHTML(APP_STRINGS.getWPMLString('DepositSubView-pending-message'))}</p>
                `}
                
                <div class="buttons-wrap">
                    <a class="btn-secondary continue-btn" @click=${()=>{ShowChatWindow()}}>${APP_STRINGS.getWPMLString('DepositSubView-fail-livechat')}</a>
                    <a class="btn-secondary continue-btn" @click=${this._onBackButtonClick}>${APP_STRINGS.getWPMLString('DepositSubView-pending-continue')}</a>
                </div>
            </div>
        `;
    }



    _renderDepositFailedView(){
        if(this._failView==false){return '';}
        return html`
            <div class="fail-view">
                <img class="icn" alt="Warning Icon" src="${`${CDN_ASSETS_URL}/icons/warning.svg`}">
                <h3>${APP_STRINGS.getWPMLString('DepositSubView-fail-title')}</h3>
                <p>${APP_STRINGS.getWPMLString('DepositSubView-fail-message')}</p>
                <div class="buttons-wrap">
                    <a class="btn-secondary continue-btn" @click=${()=>{ShowChatWindow()}}>${APP_STRINGS.getWPMLString('DepositSubView-fail-livechat')}</a>
                    <a class="btn-secondary continue-btn" @click=${this._onBackButtonClick}>${APP_STRINGS.getWPMLString('DepositSubView-fail-close')}</a>
                </div>
                
            </div>
        `;
    }

    _onBackButtonClick(e){
        this.close();
        if(this._redirectToBonuses){
            window.location.href="#bonuses"
        }
    }
}

customElements.define('deposit-result-sub', DepositResultView);