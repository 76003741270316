import {SUB_VIEW_POSITION, SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {APP_STRINGS} from "../../core/AppStrings";
import {MenuSubview} from "./MenuSubview";

export class LanguageMenuSubview extends SubViewBase{
    constructor(languageItems,mainMenuItems,position) {
        super();
        this._subViewPosition= position;
        this.mainMenuItems=mainMenuItems;
        this.languageItems=languageItems;
       
    }


    renderViewContent() {
        return html`
            <div class="menu-sub">
                <div class="menu-header">
                <img class="close-btn" alt="Close Menu Icon" src="${`${CDN_ASSETS_URL}/icons/left-arrow.svg`}" @click=${this._onCloseButtonClick} width="24" height="24">
                    <img class="back-btn" data-action="menu" alt="Back Menu Icon" src="${`${CDN_ASSETS_URL}/icons/left-arrow.svg`}" @click=${this._onBackButtonClick} width="24" height="24">
                       
                    <h3>${APP_STRINGS.getWPMLString('LanguageMenuSubView-title')}</h3>
                </div>
                <div class="menu-items">
                
                    ${this.languageItems.map(item=>html`
                        <a href="${item.link}" >
                            <img class="icon" alt="${item.label} Icon" src="${item.icon}" width="40" height="20">
                            <span>${item.label}</span>
                            ${item.active?html`<img class="tick" alt="Arrow Icon" src="${`${CDN_ASSETS_URL}/icons/Single.png`}" width="16" height="16">`:''}
                           
                        </a>
                    `)}
                    
                </div>
            </div>
        `;
    }

    _onBackButtonClick(){
        let menu = new MenuSubview(this.mainMenuItems);
        menu.open();
    }

    _onCloseButtonClick(){
       this.close();
    }




}

customElements.define('language-menu-sub',LanguageMenuSubview);