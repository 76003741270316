import {LitElement, html} from "lit-element";
import {APP_STRINGS} from "../core/AppStrings";
import {CAPP_API} from "../core/CAPPApi";
import {CAPPProgressBar} from "./CAPPProgressBar";
import {USER} from "../core/User";
import {Helpers} from "../util/Helpers";
import {CURRENCY_HELPERS} from "../util/CurrencyHelpers";

export class CAPPRaceItems extends LitElement{
    constructor() {
        super();
        this._invalidRaceData=false;
    }

    connectedCallback() {
        try {
            this._collectRaceData();
        }catch (e){
            this._invalidRaceData=true;
        }
        super.connectedCallback();
    }

    createRenderRoot() {
        return this;
    }

    render(){
        if(this._invalidRaceData){
            return html``;
        }
        return html`
            <div class="races-container">
                ${this._renderRaceItems()}
            </div>
        `;
    }

    _renderRaceItems(){
        let el=html``;

        this.raceItems.forEach(item=>{
            this.raceData=item;

            if(item.status!='coming-up'){

                if(item.leaderboard){
                    this._createLeaderboardData(item.leaderboard.Ranks.TournamentRank);
                }
                else{
                    this._createLeaderboardData([]);
                }
            }
            el=html`${el}${this._renderRaceItem()}`;
        });
        return el;
    }

    _renderRaceItem(){
        return html`
            <capp-race-item>
                <div class="race-wrap">
                    <div class="info-wrap">
                        <div class="image-wrap">
                            <img class="race-img" src="${this.raceData.image}">
                            <span class="status" data-status="${this.raceData.status}">${this.raceData.statusLabel}</span>
                        </div>
                        <div class="race-info">
                            <h2 class="race-title">${this.raceData.title}</h2>
                            <div class="prize-info">
                                <div class="prize-pool">
                                    <span class="prize-pool-value">${this.raceData.prizePool}</span>
                                    <span class="prize-pool-label">${APP_STRINGS.getWPMLString('CasinoRacesPage-prizePool-label')}</span>
                                </div>
                                <div class="places">
                                    <span class="places-value">${this.raceData.places}</span>
                                    <span class="places-label">${APP_STRINGS.getWPMLString('CasinoRacesPage-places-label')}</span>
                                </div>
                            </div>
                            ${this._renderProgressElement()}
                            <a href="${this.raceData.raceUrl}" class="${this.raceData.status=="finished"?'btn-secondary':'btn-primary'} info-link">${APP_STRINGS.getWPMLString('CasinoRacesPage-viewRace-link')}</a>
                        </div>
                    </div>

                    ${this.raceData.status=='live'?this._renderScoreTable():''}
                    ${this.raceData.status=='coming-up'?this._renderCountdown():''}
                    ${this.raceData.status=='finished'?this._renderScoreTable():''}

                    <div class="controls" >
                        <a href="${this.raceData.raceUrl}" class="${this.raceData.status=="finished"?'btn-secondary':'btn-primary'}">${APP_STRINGS.getWPMLString('CasinoRacesPage-viewRace-link')}</a>
                    </div>
                </div>
            </capp-race-item>
        `;
    }

    _collectRaceData(){
        this.raceItems=[];

        if(this.renderRoot.dataset.status){
            CAPP_API.getTournamentsAndLeaderboard(1, 4, this.renderRoot.dataset.status).then(data=>{
                let keepUpdating = false;

                data.forEach(item=>{
                    let raceData = {};
                    raceData.raceId=item.CappRaceId;
                    raceData.image=item.CappRaceImage;
                    raceData.title=item.CappRaceName;
                    raceData.prizePool=item.CappRacePrizePool;
                    raceData.places=item.CappNumberOfPositions;
                    raceData.leaderboard=item.leaderboard;
                    raceData.raceUrl=item.CappRacePermalink;

                    switch (this.renderRoot.dataset.status){
                        case "C": // C = Current/LIVE Tournament
                            keepUpdating = true;
                            raceData.status='live';
                            raceData.statusLabel='Live';
                            raceData.progressValue=item.CappRaceCompletePercentage;
                            raceData.progressLabel=item.CappRaceCompletePercentage+'%';
                            let endTimeString=item.CappTournamentEndDate;
                            raceData.endTime=Helpers.getDateFromUTCDateString(endTimeString);
                            break;
                    }
                    this.raceItems.push(raceData);
                });

                if(keepUpdating === true){
                    setInterval(()=>{
                        this.requestUpdate();
                    },1000);
                }
            })
        }
    }

    _renderRemainingTime(){
        let diff=this._getDiffDate(this.raceData.endTime);
        let timeData=this._getTimeAsArrayOfNumbers(diff);
        return html`
            <div>
                <span class="num">${timeData[0]=='0'?'':timeData[0]}</span>
                <span class="num">${timeData[1]}</span>
                <span>d&nbsp;</span>
                <span class="num">${timeData[2]}</span>
                <span class="num">${timeData[3]}</span>
                <span>h&nbsp;</span>
                <span class="num">${timeData[4]}</span>
                <span class="num">${timeData[5]}</span>
                <span>m&nbsp;</span>
                <span class="num">${timeData[6]}</span>
                <span class="num">${timeData[7]}</span>
                <span>s&nbsp;</span>
                <span>to go</span>
            </div>
        `
    }

    _renderScoreTable(){
        return html`
            <div class="table-section">
                <div class="score-table score-table-summary">
                    <div class="header-row"><span>${APP_STRINGS.getWPMLString('CasinoRace-LeaderboardTable-Pos-text')}</span></div>
                    <div class="header-row"><span>${APP_STRINGS.getWPMLString('CasinoRace-LeaderboardTable-User-text')}</span></div>
                    <div class="header-row"><span>${APP_STRINGS.getWPMLString('CasinoRace-LeaderboardTable-Points-text')}</span></div>
                    <div class="header-row"><span>${APP_STRINGS.getWPMLString('CasinoRace-LeaderboardTable-Prize-text')}</span></div>
                    ${this._scoreTableData.map((item,index)=>html`
                        <div class="${index%2==0?'odd-row':'even-row'}">
                            <span>${item.pos}</span>
                        </div>
                        <div class="${index%2==0?'odd-row':'even-row'}">
                            <span>${item.user}</span>
                        </div>
                        <div class="${index%2==0?'odd-row':'even-row'}">
                            <span>${item.points}</span>
                        </div>
                        <div class="${index%2==0?'odd-row':'even-row'}">
                            <span>${item.prize}</span>
                        </div>
                    `)}
                </div>
            </div>
        `;
    }

    _getDiffDate(targetDate){
        let now=new Date();
        let timeDiff=Math.max(0,targetDate.getTime()-now.getTime());
        let diff=new Date(timeDiff);
        return diff;
    }

    _getTimeAsArrayOfNumbers(date){
        let days = Math.floor(date.getTime() / (24*60*60*1000));
        let daysms = date.getTime() % (24*60*60*1000);
        let hours = Math.floor(daysms / (60*60*1000));
        let hoursms = date.getTime() % (60*60*1000);
        let minutes = Math.floor(hoursms / (60*1000));
        let minutesms = date.getTime() % (60*1000);
        let seconds = Math.floor(minutesms / 1000);


        let timeArray=[];
        timeArray=timeArray.concat(String(days).padStart(2, '0').split(''));
        timeArray=timeArray.concat(String(hours).padStart(2, '0').split(''));
        timeArray=timeArray.concat(String(minutes).padStart(2, '0').split(''));
        timeArray=timeArray.concat(String(seconds).padStart(2, '0').split(''));

        return timeArray;
    }

    _renderCountdown(){
        let diffDate=this._getDiffDate(this.raceData.startTime);
        let timeData=this._getTimeAsArrayOfNumbers(diffDate);

        return html`
            <div class="countdown">
                <div>
                    <div class="start-label">${APP_STRINGS.getWPMLString('CasinoRaceItem-StartsIn-text')}</div>
                    <div class="countdown-grid">
                        <div class="num"><span>${timeData[0]}</span></div>
                        <div class="num"><span>${timeData[1]}</span></div>
                        <div class="sep"><span>:</span></div>
                        <div class="num"><span>${timeData[2]}</span></div>
                        <div class="num"><span>${timeData[3]}</span></div>
                        <div class="sep"><span>:</span></div>
                        <div class="num"><span>${timeData[4]}</span></div>
                        <div class="num"><span>${timeData[5]}</span></div>
                        <div class="sep"><span>:</span></div>
                        <div class="num"><span>${timeData[6]}</span></div>
                        <div class="num"><span>${timeData[7]}</span></div>
                        <div class="cd-label days">${APP_STRINGS.getWPMLString('CasinoRaceItem-Days-text')}</div>
                        <div class="cd-label hours">${APP_STRINGS.getWPMLString('CasinoRaceItem-Hours-text')}</div>
                        <div class="cd-label minutes">${APP_STRINGS.getWPMLString('CasinoRaceItem-Minutes-text')}</div>
                        <div class="cd-label seconds">${APP_STRINGS.getWPMLString('CasinoRaceItem-Seconds-text')}</div>
                    </div>
                </div>
            </div>
        `;
    }

    _renderProgressElement(){
        let el;
        switch (this.raceData.status){
            case "live":
                el=html`
                    <div class="tournament-progress">
                        <capp-progress-bar value="${this.raceData.progressValue}" layout="${CAPPProgressBar.LAYOUT_TYPE.SLIM}"></capp-progress-bar>
                        <div class="progress-labels">
                            <span>${this.raceData.progressLabel}</span>
                            ${this._renderRemainingTime()}
                        </div>
                    </div>
                `;
                break;
            case 'finished':
                el=html`
                    <div class="tournament-progress">
                        <capp-progress-bar class="finished" value="100" layout="${CAPPProgressBar.LAYOUT_TYPE.SLIM}"></capp-progress-bar>
                        <div class="progress-labels">
                            <span>100%</span>
                            <span>${APP_STRINGS.getWPMLString('CasinoRaceItem-TimeUp-text')}</span>
                        </div>
                    </div>
                `;
                break;
            default:
                el='';
        }
        return el;
    }

    _createLeaderboardData(data){
        let currencySymbol=USER.loggedIn ? USER.data.CurrencySymbol : window.defaultCurrencySymbol;
        this._scoreTableData=[];

        for(let i=0; i<4; i++){
            let item={
                pos:'-',
                user:'-',
                points:'-',
                prize:'-'
            }

            if(data[i]){
                item.pos=data[i].Rank;
                item.user=data[i].FirstName;
                item.points=parseInt(data[i].Score);
                item.prize=`${currencySymbol}${CURRENCY_HELPERS.formatCurrency(data[i].PrizeAmount)}`;
            }
            this._scoreTableData.push(item);
        }

    }
}

customElements.define('capp-race-items', CAPPRaceItems);