import {EventEmitter} from "events";
import {USER} from "./User";
import {ShoutoutPopup} from "../components/popups/ShoutoutPopup";

class FastTrack extends EventEmitter{
    constructor() {
        super();

        this._configUrl=null;
        this._pusherChannel=null;
        this._brandName=null;
        this._messages=null;
        this._notifications=null;
        this._loadingMessages=true;
        this._notificationsQueue=[];

    }

    get messages(){
        return this._messages;
    }

    get numUnreadMessages(){
        if(this._messages==null){return 0;}
        return this._messages.filter(msg=>msg.IsRead==false).length;
    }

    initialize(configUrl,pusherChannel,brandName){
        if(!USER.isLoggedIn){
            return;
        }

        this._configUrl=configUrl;
        this._pusherChannel=pusherChannel;
        this._brandName=brandName;
        console.log('++++INITIALIZING FASTTRACK++++');
        console.log(this._configUrl);
        console.log(this._pusherChannel);
        console.log(this._brandName);
        console.log('++++++++++++++++++++++++++++++');

        console.log('initializing fast track',this);
        this._loadConfiguration()
            .then(data => this._login())
            .then(data => {
                console.log('fasttrack fully initialized');
                const pusherChannelName = this._getPusherChannelName(this._pusherChannel, this.userId);

                console.log(pusherChannelName, 'pusherName');
                this._initiatePusher(this._config.fusionUrl, this._config.pusherKey, pusherChannelName);
                this.loadMessages();
            }).catch(e=>{
            console.log('error initializing fasttrack');
        })

    }

    _loadConfiguration(){
        return this._apiRequest('GET',this._configUrl,null,true)
            .then(data=>{
                this._config=data;
                console.log('loaded fasttrack config',this._config);
            });

    }

    _login(){
        let url=`${this._config.fusionUrl}/Platform/LoginAuthToken`;
        return this._apiRequest('POST',url)
            .then(data=>{
                if(data.Data.JwtToken){
                    this.userId=data.Data.User.UserId;
                    this.apiToken=data.Data.JwtToken;
                    this.authToken=data.Data.Authentication.AuthToken;
                    console.log('fasttrack login success',this);
                }

            })


    }

    loadMessages(){
        let url=`${this._config.fusionUrl}/Notifications/v2/user-notifications?unread-only=false&inbox-only=false`;
        return this._apiRequest('GET',url)
            .then(data=>{
                console.log('messages data',data);
                if(data.Success== true){
                    let msgTypes={};
                    this._messages=[];
                    this._notifications=[];

                    data.Data.forEach(msg=>{
                        msgTypes[msg.Event]=true;
                        switch (msg.Event){
                            case 'inbox':
                                this._messages.push(msg);
                                break;
                            case 'shoutout':
                            case 'message':
                                this._notifications.push(msg);
                                break;
                            default:
                                //this._messages.push(msg);
                        }
                    })
                    console.log('messages',this._messages);

                    this._processNotifications();
                }
                this._loadingMessages=false;
                this.emit("messagesLoaded");

            })

    }
    /*
    no messages response
    '{"Data":[],"Success":true,"Errors":[]}'
    * */
    markMessageAsRead(messageId){
        let url=`${this._config.fusionUrl}/Notifications/MarkNotificationAsRead`;
        let reqData={
            MessageId: messageId
        };
        return this._apiRequest('POST',url,reqData)
            .then(data=>{
                console.log('mark as read message response',data)
                if(data.Success==true){
                    let msg=this.messages.find(item=>item.MessageId==messageId);
                    msg.IsRead=true;
                    this.emit('messagesChanged',messageId);
                }
            })
    }

    _deleteMessage(messageId){
        let url=`${this._config.fusionUrl}/Notifications/v2/user-notification/${messageId}`;
        return this._apiRequest('DELETE',url)
            .then(data=>{
                console.log('delete message response',data);
                if(data.Success){
                    this._messages=this._messages.filter(item=>item.MessageId!=messageId);
                }
                return data.Success;
            })
    }

    deleteMessages(messageIds){
        let promises=[];
        messageIds.forEach(item=>{
            promises.push(this._deleteMessage(item));
        })

        return Promise.all(promises).then(results=>{
            let success=true;
            results.forEach(res=>{
                success=success&&res;
            })
            if(success==false){
                throw new Error("Error deleting messages");
            }
        });
    }


    _apiRequest(method,url,data,omitAuthHeader){
        console.log('make api req',url);

        let parameters={
            method: method, // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        }

        if(!omitAuthHeader){
            parameters.headers={'authtoken': USER.sessionKey}
        }

        if(data){
            parameters.headers['Content-Type']='application/json';
            parameters.body=JSON.stringify(data);
        }



        return fetch(url,parameters)
            .then(response=>{
            if(response.ok){
                return response.json();
            }else{
                throw new Error("fasttrack config error "+response.status);
            }

        })
    }

    // _processNotifications(){
    //     console.log('_processNotifications',this._notifications);
    //     if(this._notifications && this._notifications.length>0){
    //         let notification=this._notifications[0];
    //         let expires=new Date(notification.Expires);
    //         let now=new Date();
    //         if(now<expires){
    //             new ShoutoutPopup(this._notifications[0]);
    //         }
    //
    //         let notificationsToDelete=this._notifications.map(item=>item.MessageId);
    //         console.log('notificationsToDelete',notificationsToDelete);
    //         this.deleteMessages(notificationsToDelete);
    //     }
    // }

    _processNotifications(){
        //reserialize the array, for somewhat reason array length is incorrect as it is
        this._notifications = JSON.parse(JSON.stringify(this._notifications));
        // console.log('_processNotifications',this._notifications, this._notifications.length, Object.keys(this._notifications));
        if(this._notifications && this._notifications.length>0){
            // console.log(this._notifications.length > 3 ? this._notifications.slice(this._notifications.length - 3, 0) : this._notifications)
            this._notificationsQueue = this._notifications.length > 3 ? this._notifications.slice(this._notifications.length - 3, 0) : this._notifications;
            // console.log(this._notificationsQueue);
            this._notificationsQueue = JSON.parse(JSON.stringify(this._notificationsQueue));
            // console.log(this._notificationsQueue);
            setInterval(()=>{
                //check if there is already a pop up
                var active_shout = document.querySelector(".shoutout-wrap");

                // console.log('check active', active_shout);
                if (this._notificationsQueue.length > 0 && !active_shout) {
                    var notification = this._notificationsQueue.pop();

                    new ShoutoutPopup(notification);
                    this.deleteMessages([notification.MessageId]);
                    console.log('notificationToDelete', [notification.MessageId]);
                }
            }, 5000);
        }
    }

    _initiatePusher(fusionUrl, pusherKey, channelName) {
        // Setup pusher object:
        const PUSHER = new Pusher(pusherKey, {
            authEndpoint: fusionUrl + '/external/pusher/'+ this._brandName +'?authToken=' + USER.sessionKey,
            cluster: this._config.pusherRegion,
            encrypted: true,
        });
        PUSHER.connection.bind("connected", function (data) {
            console.log("Connected to pusher:", data);
        });
        PUSHER.connection.bind('disconnected', function (data) {
            console.error("Disconnected from pusher: ", data);
        });
        // Bind to channel:
        const PUSHER_CHANNEL = PUSHER.subscribe(channelName);
        PUSHER_CHANNEL.bind("pusher:subscription_error", function (data) {
            console.error("Pusher channel error: ", data);
        });
        PUSHER_CHANNEL.bind("pusher:subscription_succeeded", function (data) {
            console.log("Successfully connected to pusher channel: ", data);
        });
        // Bind to specific message types:
        PUSHER_CHANNEL.bind("message", function (data) {
            console.log("Got pusher message: ", data);
            this.dispatchEvent('PusherMessage', data);
        });
        PUSHER_CHANNEL.bind("shoutout", function (data) {
            // this.dispatchEvent('PusherShoutOut', data);
            console.log("Got pusher shoutout: ", data);
            // insert into processing queue
            this._notificationsQueue.push(data);
        });
        PUSHER_CHANNEL.bind("inbox", function (data) {
            console.log("Got pusher inbox message: ", data);
            this.dispatchEvent('PusherInbox', data);
        });
    }

    _getPusherChannelName(brandId, userID) {
        return 'private-prisma-' + brandId + '-' + userID + '';
    }
}

export const FAST_TRACK = new FastTrack();