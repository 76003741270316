import {LitElement,html} from "lit-element";

export class CAPPPreloader extends LitElement{

    static get properties() {
        return {
            size: {type:Number}
        };
    }

    constructor() {
        super();
    }

    createRenderRoot() {
        return this;
    }

    render(){
        let pSize=`${this.size?this.size:1.25}em`;
        return html`
            <div class="preloader" style="width: ${pSize};height: ${pSize}"></div>
        `;
    }
}
customElements.define('capp-preloader', CAPPPreloader);