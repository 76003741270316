import {CAPPExpandSection} from "./CAPPExpandSection";

export class CAPPExpandSectionWithImages extends CAPPExpandSection{

    constructor() {
        super();
    }

    firstUpdated(_changedProperties) {

        this._initPictureElement();
        super.firstUpdated(_changedProperties);
    }

    _initPictureElement(){
        let images=this.moreContentElement.querySelectorAll('img');
        this._initObserver();


        images.forEach(image=>{
            if(image.dataset.cfResizerSrc){
                let url=image.dataset.cfResizerSrc;
                let picture=document.createElement('picture');

                picture.appendChild(this._createSourceElement(url,'webp','small'));
                picture.appendChild(this._createSourceElement(url,'jpeg','small'));
                picture.appendChild(this._createSourceElement(url,'webp','large'));
                //picture.appendChild(this._createSourceElement(url,'jpeg','large'));


                let imgClone=image.cloneNode();
                //imgClone.dataset.src=url;
                imgClone.dataset.src=this._getImageUrl(url,'jpeg',"large");
                imgClone.src="";
                picture.appendChild(imgClone)
                image.parentNode.replaceChild(picture,image);
                this._imageObserver.observe(picture);
            }


        })
    }

    _createSourceElement(url,type,size){
        let source=document.createElement('source');

        let mediaRule=size=="small"?"(max-width: 720px)":"(min-width: 720px)"
        source.setAttribute('media',mediaRule);
        source.setAttribute('data-srcset',this._getImageUrl(url,type,size))
        return source;
    }

    _getImageUrl(url,type,size){
        let w,h;
        switch (size){
            case "small":
                w=720;
                break;
            case "large":
                w=1200;
                break;
        }

        let resizeUrl = url;
        if (window.isCFCDNEnabled) { resizeUrl=`${window.CFResizerURL}/width=${w},fit=scale-down,quality=90,format=${type}/${url}`; }

        return resizeUrl;
    }

    _initObserver(){
        if("IntersectionObserver" in window){
            let imageObserver=new IntersectionObserver((entries, observer)=> {
                entries.forEach(entry=> {
                        if (entry.isIntersecting) {
                            //this._loadImage(entry.target);
                            this._imageObserver.unobserve(entry.target);
                            this._loadImage(entry.target);
                        }
                    }
                );
            },{
                rootMargin: "0px 300px 300px 0px",
                threshold: 0.0
            });
            this._imageObserver=imageObserver;
        }
    }

    _loadImage(picture){
        let sourceElements=picture.querySelectorAll('source');
        for(let i=0;i<sourceElements.length; i++){
            sourceElements[i].srcset=sourceElements[i].dataset.srcset;
        }
        let imgEl=picture.querySelector('img');
        imgEl.src=imgEl.dataset.src;
    }
}

customElements.define('capp-expand-section-images', CAPPExpandSectionWithImages);