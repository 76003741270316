import {SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import {CAPP_API} from "../../core/CAPPApi";
import {USER} from "../../core/User";
import {SubViewManager} from "../../controllers/SubViewManager";
import {CAPPPopup} from "../popups/CAPPPopup";
import {Helpers} from "../../util/Helpers";
import {APP_STRINGS} from "../../core/AppStrings";
import {CURRENCY_HELPERS} from "../../util/CurrencyHelpers";


const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();
export class GameInfoSubview extends SubViewBase{
    constructor(gameId, gameUrl, gameData, pageMode,searchGameId) {
        super();
        this._gameId=gameId;
        this._gameUrl=gameUrl;
        this._moreInfoSectionVisible=true;
        this._sid = searchGameId ? searchGameId : '';

        this._useInPageMode=pageMode;
        this._gameData=gameData?gameData:null;
        console.log('gameData',this._gameData);

        this._platform=Helpers.getDeviceType();

        this._loadingRealGameUrl=false;
        this._loadingFunGameUrl=false;

        if(this._useInPageMode){
            this.classList.add('page-mode');
        }

        if(this._gameData==null){
            this._loadGameInfo();
        }

    }



    addToSubviewManager(){
        if(this._useInPageMode){return;}
        SubViewManager.add(this);
    }

    _loadGameInfo(){
        this._loadingGameInfo=true;
        CAPP_API.getGameInfo(this._gameId,'en').then(data=>{
            console.log('loaded game info',data);
            if(data){
                if(data.error){
                    new CAPPPopup(
                        APP_STRINGS.getWPMLString('Common-Error'),
                        data.error.text,
                        [{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-primary'}]
                    )
                    this.close();
                }else{
                    this._gameData=data;
                    if(typeof this._gameUrl=="undefined"){
                        this._gameUrl=this._gameData.gameInfoLink;
                    }
                    this._loadingGameInfo=false;
                    this.requestUpdate();
                }
            }
        })
    }


    renderViewContent() {
        if(this._loadingGameInfo){
            return html`<capp-preloader class="data-loader"></capp-preloader>`;
        }
        return html`
            <div class="close-btn" @click=${this._onCloseBtnClick}>
                <img alt="Close Button Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}">
            </div>
            <div class="game-info-wrap">
                <div class="game-image-wrap">
                    ${this._renderPictureElement()}
                </div>

                <section class="game-title-wrap">
                    <h1>${unsafeHTML(this._gameData.title)}</h1>
                    
                    <div class="game-links ${USER.isLoggedIn && this._platform=='desktop'?'single':''}">
                        ${USER.isLoggedIn?html`
                            <button class="btn-primary ${this._loadingRealGameUrl?'loading':''}" @click=${this._onRealPlayClick}>${APP_STRINGS.getWPMLString('GameInfoSubView-play')}</button>
                            ${this._platform!='desktop'?html`
                                <button class="btn-secondary ${this._loadingFunGameUrl?'loading':''}" @click=${this._onDemoPlayClick}>${APP_STRINGS.getWPMLString('GameInfoSubView-demoPlay')}</button>
                            `:''}
                        `:html`
                            <button class="btn-primary" @click=${this._onLoginClick}>${APP_STRINGS.getWPMLString('GameInfoSubView-loginToPlay')}</button>
                            <button class="btn-secondary ${this._loadingFunGameUrl?'loading':''}" @click=${this._onDemoPlayClick}>${APP_STRINGS.getWPMLString('GameInfoSubView-demoPlay')}</button>
                        `}
                        
                    </div>
                    
                </section>
                ${this._gameData.summaryText && this._gameData.summaryText!=""?html`
                    <hr class="title-separator">
                    <section>
                        <div class="description">${this._gameData.summaryText}</div>
                    </section>

                `:''}
                <hr class="title-separator">
                
                ${this._renderGameSpecs(this._gameData)}
                <hr>
                <section class="more-info">
                    <capp-accordion cadd="${this._platform != "mobile" ? '105' : '50'}">
                        <div class="title expanded">${APP_STRINGS.getWPMLString('GameInfoSubView-moreInfo')}</div>
                        <div class="content">
                            ${unsafeHTML(this._gameData.content)}
                        </div>
                    </capp-accordion>
                </section>
                
            </div>
        `
    }

    _renderPictureElement(){
        return html`
            <picture>
                <source srcset="${this._getImageUrl('webp')}"  />
                <img class="game-image"
                        src="${this._getImageUrl('jpeg')}"
                        title="${this._gameData.lobbyThumbnailTitleTag || ''}"
                        alt="${this._gameData.lobbyThumbnailAltTag || ''}"
                >
            </picture>
        `;
    }

    _getImageUrl(type){
        let url = this._gameData.lobbyThumbnailUrl;
        if (window.isCFCDNEnabled) { url = `${window.CFResizerURL}/width=382,height=240,quality=90,format=${type}/${this._gameData.lobbyThumbnailUrl}`; }
        return url
    }

    _renderGameSpecs(){
        let gameSpecs=this._getGameSpecs();

        return html`
            <section class="game-specs">
                    <capp-accordion>
                        <div class="title expanded">${APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecs')}</div>
                        <div class="content">
                            <div class="specs-grid">
                                ${gameSpecs.map(item=>html`
                                    <div class="row">
                                        <span class="label">${item.label}</span>
                                        <span class="value">${item.value}</span>
                                    </div>
                                `)}
                            </div>
                        </div>
                    </capp-accordion>
                </section>
                
        `;
    }

    _getGameSpecs(){
        let isSlots=this._gameData.gameCategories.indexOf('Slots')>=0?true:false;
        let currency=USER.isLoggedIn?USER.data.CurrencySymbol:'';
        let gameSpecs=[];

        if(this._gameData.gameCategory && this._gameData.gameCategory.length>0){
            gameSpecs.push({label:APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-gameType'),value:this._gameData.gameCategory})
        }

        if(this._gameData.gameMakers && this._gameData.gameMakers.length>0){
            gameSpecs.push({label:APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-gameProvider'),value:this._gameData.gameMakers.join(', ').split('@')[0]})
        }

        if(isSlots){
            if(this._gameData.numberOfLine && this._gameData.numberOfLine.length>0){
                gameSpecs.push({label:APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-numLines'),value:this._gameData.numberOfLine[this._gameData.numberOfLine.length-1]})
            }

            if(this._gameData.numOfReel && this._gameData.numOfReel.length>0){
                gameSpecs.push({label:APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-numReels'),value:this._gameData.numOfReel})
            }

        }

        if(this._gameData.minBet){
            gameSpecs.push({label:APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-minBet'),value:currency+''+CURRENCY_HELPERS.formatCurrencyWithoutConversion(this._gameData.minBet)})
        }

        if(this._gameData.maxBet){
            gameSpecs.push({label:APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-maxBet'),value:currency+''+CURRENCY_HELPERS.formatCurrencyWithoutConversion(this._gameData.maxBet)})
        }

        let yes=APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-yes');
        let no=APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-no');

        if(isSlots){
            gameSpecs.push({label:APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-bonusGame'),value:this._gameData.isBonus?yes:no})
            gameSpecs.push({label:APP_STRINGS.getWPMLString('GameInfoSubView-gameSpecField-jackpot'),value:this._gameData.isJackport?yes:no})
        }
        return gameSpecs;
    }

    _onCloseBtnClick() {
        let route = '';

        if (this._useInPageMode) {
            if (document.referrer === '') {
                route = '/';
            } else {
                if (sessionStorage.getItem('isNotSingleGamePage')) {
                    route = sessionStorage.getItem('isNotSingleGamePage');
                    sessionStorage.removeItem('isNotSingleGamePage');
                } else {
                    route = document.referrer;
                }
            }

            window.location.href = route;
        } else {
            this.close();
        }
    }

    close() {
        if(this._useInPageMode==true){
            //do nothing
        }else{
            super.close();
        }

    }

    forceClose(){
        super.close();
    }

    _onLoginClick(){
        window.location.href="#login";
    }

    _onDemoPlayClick(){
        this._loadingFunGameUrl=true;
        this.requestUpdate();
        if(this._platform=='desktop'){
            window.location.href=this._gameUrl;
        }else{
            this._loadGameUrl(false);
        }

    }



    _onRealPlayClick(){
        this._loadingRealGameUrl=true;
        this.requestUpdate();

        if(this._platform=='desktop'){
            window.location.href=this._gameUrl;
        }else{
            this._loadGameUrl(true);
        }

    }

    _loadGameUrl(real){
        let lang=USER.isLoggedIn?USER.data.LanguageISO:LANGUAGE;
        let fun=real?0:1;
        this._setLoadingMode(true);

        CAPP_API.getGameUrl(real?USER.sessionKey:'',this._gameId, fun,lang,this._sid).then(data=>{
            console.log('CAPP_API.getGameUrl',data);

            if(data){
                if(data.error){
                    new CAPPPopup(
                        APP_STRINGS.getWPMLString('Common-Error'),
                        data.error.text,
                        [{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-primary'}]
                    )
                    this._loadingFunGameUrl=false;
                    this._loadingRealGameUrl=false;
                    this._setLoadingMode(false);
                    this.requestUpdate();
                }else{
                    window.location.href=data.url;
                }
            }


        })
    }

    _setLoadingMode(val){
        if(val){
            this.classList.add('loading');
        }else{
            this.classList.remove('loading');
        }
    }

}

customElements.define('game-info-sub',GameInfoSubview);


