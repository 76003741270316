import {SubViewManager} from "../../../../core/capp-V3.5-js-lib/src/js/controllers/SubViewManager";
import {CAPP_API} from "../../../../core/capp-V3.5-js-lib/src/js/core/CAPPApi";
import {PAGE_SETTINGS} from "../../../../core/capp-V3.5-js-lib/src/js/core/PageSettings";
import {APP_STRINGS} from "../../../../core/capp-V3.5-js-lib/src/js/core/AppStrings";
import {USER} from "../../../../core/capp-V3.5-js-lib/src/js/core/User";
import {CAPP_ROUTER} from "../core/CAPPRouter";
import {Helpers} from "../../../../core/capp-V3.5-js-lib/src/js/util/Helpers";

import {CAPPPreloader} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPPreloader";
import {CAPPSiteNotification} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPSiteNotification";
import {CAPPAddToHomeScreen} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPAddToHomeScreen";
import {FormHelpers} from "../../../../core/capp-V3.5-js-lib/src/js/util/FormHelpers";
import {CAPPInput} from "../../../../core/capp-V3.5-js-lib/src/js/components/form-elements/CAPPInput";
import {CAPPAmountInput} from "../../../../core/capp-V3.5-js-lib/src/js/components/form-elements/CAPPAmountInput";
import {CAPPText} from "../../../../core/capp-V3.5-js-lib/src/js/components/form-elements/CAPPText";
import {CAPPSelect} from "../../../../core/capp-V3.5-js-lib/src/js/components/form-elements/CAPPSelect";
import {CAPPMultiselect} from "../../../../core/capp-V3.5-js-lib/src/js/components/form-elements/CAPPMultiselect";
import {CAPPCheckbox} from "../../../../core/capp-V3.5-js-lib/src/js/components/form-elements/CAPPCheckbox";
import {CAPPRadio} from "../../../../core/capp-V3.5-js-lib/src/js/components/form-elements/CAPPRadio";
import {CAPPDatepicker} from "../../../../core/capp-V3.5-js-lib/src/js/components/form-elements/CAPPDatepicker";
import {CAPPAccordion} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPAccordion";
import {CAPPCountdown} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPCountdown";
import {CAPPCookieAccept} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPCookieAccept";
import {RestrictedCountryPopup} from "../../../../core/capp-V3.5-js-lib/src/js/components/popups/RestrictedCountryPopup";

import {LoginSubview} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/LoginSubview";
import {RegistrationSubview} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/RegistrationSubview";
import {ResetPasswordSubView} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/ResetPasswordSubView";
import {CAPPPopup} from "../../../../core/capp-V3.5-js-lib/src/js/components/popups/CAPPPopup";
import {DepositResultView} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/DepositResultView";
import {PasswordResetProcessorPopup} from "../../../../core/capp-V3.5-js-lib/src/js/components/popups/PasswordResetProcessorPopup";
import {InitLivechatController} from "../../../../core/capp-V3.5-js-lib/src/js/controllers/LivechatController";
import {AccountActivationProcessorPopup} from "../../../../core/capp-V3.5-js-lib/src/js/components/popups/AccountActivationProcessorPopup";
import {LazyImageLoader} from "../../../../core/capp-V3.5-js-lib/src/js/util/LazyImageLoader";
import {SubViewBase} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/SubViewBase";

const LANGUAGE=Helpers.getTranslatedLanguageFromCookie();
export class CAPPPageBaseAbstractClass {
    constructor() {
        this._waitForAPPStrings();
        SubViewBase.CLOSE_ON_MASK_CLICK=false;
    }

    init(){
        throw new Error('Implement init method in subclass');
    }

    _waitForAPPStrings(){
        APP_STRINGS.on("WPML_Ready",()=>{
            console.log("WPML loaded!!!");
            console.log('CAPP STRINGS',APP_STRINGS);
            this._onAPPStringsReady();
        });
        APP_STRINGS._init();
    }

    _onAPPStringsReady(){
        console.log('USER',USER);

        this._setLocalRegulationsMode();
        this._setAffiliateAcquisitionTracking();
        this._listenForServerErrors();
        this._initializeRouter();
        this.init();

        if(window.isCookieWarningShow == true){
            this._checkCookieAccept();
        }

        this._pageLoaded();
        if(USER.isLoggedIn){USER.updatePlayerBalance();}
    }

    _setLocalRegulationsMode(){
        if(window.localRegulationMode && window.localRegulationMode!="none"){
            RegistrationSubview.REGISTRATION_MODE=RegistrationSubview.MODE_TYPES.LOCAL_REGULATIONS;
        }
    }

    _setAffiliateAcquisitionTracking(){
        //Check and remove the expired affData from localStorage(if exist)
        if(window.localStorage.getItem('affData')){
            console.log(window.localStorage.getItem('affData'), 'affData');

            try{
                const affData = JSON.parse(window.localStorage.getItem('affData'));
                const currentDate = new Date().toJSON();
                console.log(currentDate, 'currentDate');

                if((typeof affData) === 'object' && affData !== null){

                    if(affData.expiry <= currentDate){
                        //Remove the expired affData
                        console.log(affData.expiry, 'Removing the expired affData');
                        window.localStorage.removeItem('affData');
                    }
                }
            }catch (e){
                console.log(e);
            }
        }

        let url = new URL(window.location.href);

        if(url.searchParams.get("affToken") || window.localStorage.getItem('affData') === null){
            console.log(url.searchParams, '_setAffiliateAcquisitionTracking');
            const expiryDays = 30; // expires in days
            let d = new Date();
            d.setTime(d.getTime() + expiryDays*24*60*60*1000);

            const affData = {
                expiry: d.toJSON(),
                affToken: url.searchParams.get("affToken"),
                affID: url.searchParams.get("affID"),
                affDetails: url.searchParams.get("affDetails"),
                referer: url.searchParams.get("referer"),
                refererURL: document.referrer
            };
            window.localStorage.setItem('affData', JSON.stringify(affData));
        }
    }

    _pageLoaded(){
        this._initHeaderSizeObserver();
        this._addFooterImagesToLazyLoad();
        this._moveInfoContent();
        document.body.classList.remove("loading");

        PAGE_SETTINGS.on("PageSettings_Ready",()=>{
            console.log("Page Settings loaded!!!");

            this._checkIfCountryRestricted();
            this._checkIfSiteHasMaintenanceNotice();
            //this._checkIfAddToHomeScreen();
            InitLivechatController(false);
        });
        PAGE_SETTINGS._init();
    }

    _addFooterImagesToLazyLoad(){
        let images=document.querySelectorAll("footer img");
        let imagesToLazyLoad=[];
        images.forEach(item=>{
            if(typeof item.dataset.src != "undefined"){
                imagesToLazyLoad.push(item);
            }
        })
        LazyImageLoader.addImagesToObserve(imagesToLazyLoad);
    }

    _listenForServerErrors(){
        CAPP_API.on("ServerError",(error)=>{
            console.log("server error event",error);
            new CAPPPopup(APP_STRINGS.getWPMLString('serverError-popup-title'),error,[{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-primary'}]);
        });

        CAPP_API.on("SessionExpired",(error)=>{
            console.log("SESSION EXPIRED",error);
            new CAPPPopup(APP_STRINGS.getWPMLString('serverError-popup-title'),error.text,[{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-primary'}],()=>{
                SubViewManager.closeCurrentSubview();
                USER.logout(true);
            });
        });
    }

    _initializeRouter(){
        CAPP_ROUTER.initialize();
        CAPP_ROUTER.on('route-changed',this._handleRouteChange);
        this._handleRouteChange(CAPP_ROUTER.routeData);
        setTimeout(()=>{
            this._checkForExternalWindowPaymentCallbacks()
            this._processUrlQueryParameters();
        },100);
    }



    _checkForExternalWindowPaymentCallbacks(){
        let url = window.location.href;
        if(url.indexOf("deposit-success")>0){
            new DepositResultView('success');

            if(USER.isLoggedIn){
                USER.updateUserData();
            }
        }else if(url.indexOf("deposit-failure")>0){
            new DepositResultView('fail');
        }else if(url.indexOf("deposit-pending")>0){
            new DepositResultView('pending');
        }
    }

    _processUrlQueryParameters(){
        let url=new URL(window.location.href);
        let value;
        if(value=url.searchParams.get("resetCode")){
            console.log('resetCode',value);
            window.history.replaceState({}, document.title, '/'+LANGUAGE+"/#");
            new PasswordResetProcessorPopup(value);
        }else if(value=url.searchParams.get("activateCode")){
            window.history.replaceState({}, document.title, '/'+LANGUAGE+"/#");
            new AccountActivationProcessorPopup(value,url.searchParams.get("ea"));
        }
    }


    _handleRouteChange(routeData){
        throw new Error('route handler needs to be implemented in a subclass');
    }

    _checkIfCountryRestricted(){
        if(window.localRegulationMode && window.localRegulationMode!="none"){
            this._handleLocalRegulations(window.localRegulationMode);
        }else if(window.isCountryRestricted){
            new RestrictedCountryPopup(csrfToken);
        }
    }

    _checkIfSiteHasMaintenanceNotice(){
        new CAPPSiteNotification(window.maintenanceNotice);
    }

    _handleLocalRegulations(mode){
        let storage;
        switch (mode){
            case "session":
                storage=window.sessionStorage;
                break;
            case "infinite":
                storage=window.localStorage;
                break;
            default:
                storage=window.localStorage;
        }

        if(window.localRegulationPopup){

            if(storage.getItem('localRegulationsDisplayed')=="true"){
                return;
            }

            new CAPPPopup(
                APP_STRINGS.getWPMLString('LocalRegulationsPopup-title'),
                APP_STRINGS.getWPMLString('LocalRegulationsPopup-text'),
                [{label:APP_STRINGS.getWPMLString('LocalRegulationsPopup-acceptButton'),type:'btn-primary'}],
                ()=>{
                    storage.setItem('localRegulationsDisplayed',"true");
                }
            )
        }
    }

    _checkCookieAccept(){
        let cookiesAccepted=Helpers.getCookie('cookiesAccept')=="true";
        if(!cookiesAccepted){
            new CAPPCookieAccept();
        }
    }

    _initHeaderSizeObserver(){
        let header=document.body.querySelector('header');
        if(header==null){
            return;
        }

        let contentContainer=document.body.querySelector('#content-container');

        const resizeObserver=new ResizeObserver(entries => {
            contentContainer.style.paddingTop=`${entries[0].contentRect.height}px`;

        })
        resizeObserver.observe(header);
    }

    _moveInfoContent(){
        let infoSection=document.body.querySelector('.page-info');
        let footerEl=document.body.querySelector('footer');
        let languageSection=document.body.querySelector('#language-sub-navigation');

        if(infoSection != null && typeof infoSection != null){
            footerEl.parentNode.insertBefore(infoSection,footerEl);
            infoSection.classList.remove("loading");
        }

        if(languageSection != null && typeof languageSection != null){
          
            languageSection.classList.remove("loading");
        }

    }

    _checkIfAddToHomeScreen(){
        console.log(window.noCachePageSettings);
        if ((typeof window.noCachePageSettings) != 'undefined' && "mobileConfig" in window.noCachePageSettings){
            // document.querySelector('head').append('<style>.ath-container:before{content:"'+$("#mobileAppDownloadTitle").val()+'" !important;}</style>');

            if(window.noCachePageSettings.mobileConfig.isDownloadAppAllowed || window.noCachePageSettings.mobileConfig.isAddToHomeScreenAllowed){
                // to follow the skipFirstVisit behaviour && maxDisplayCount
                if (localStorage.getItem("emu") && Number(localStorage.getItem("displayCount")) <= 2) {
                    localStorage.setItem("displayCount", Number(localStorage.getItem("displayCount")) + 1);
                    new CAPPAddToHomeScreen();
                } else {
                    localStorage.setItem("emu", location.href);
                    localStorage.setItem("displayCount",0);
                }


                // window.ath=window.addToHomescreen({
                //     maxDisplayCount: 2,
                //     skipFirstVisit: true,
                //     message: $("#mobileAppDownloadText").val()+"<br><b><a href='"+ CAPP.settings.pageSettings.noCachePageSettings.mobileConfig.downloadAppUrl+"'>"+ $("#mobileAppDownloadLinkLabel").val()+"</a></b>",
                //     onShow:function(){
                //         $(".ath-container .ath-application-icon").on("click",function(event){
                //             window.location.href= CAPP.settings.pageSettings.noCachePageSettings.mobileConfig.downloadAppUrl;
                //         });
                //     }

                // });
            }
        }
    }


}