import {CAPPLoggedOutPageBaseClass} from "./ui/CAPPLoggedOutPageBaseClass";

console.log('HOME PAGE LOGGED OUT - STARTING the CAPP-V3.5 app');
import {CAPPHero} from "../../../core/capp-V3.5-js-lib/src/js/components/CAPPHero";
import {CAPPNewsFeed} from "../../../core/capp-V3.5-js-lib/src/js/components/CAPPNewsFeed";import {CAPPGameTile} from "../../../core/capp-V3.5-js-lib/src/js/components/CAPPGameTile";
import {CAPPGameTileRecommendedGames} from "../../../core/capp-V3.5-js-lib/src/js/components/CAPPGameTileRecommendedGames";
import {CAPPGameTileLastPlayed} from "../../../core/capp-V3.5-js-lib/src/js/components/CAPPGameTileLastPlayed";
import {CAPPJackpotsTile} from "../../../core/capp-V3.5-js-lib/src/js/components/CAPPJackpotsTile";
import {CAPPGameTileBigWinners} from "../../../core/capp-V3.5-js-lib/src/js/components/CAPPGameTileBigWinners";
import {CAPPTopList} from "../../../core/capp-V3.5-js-lib/src/js/components/CAPPTopList";
import {CAPPRaceItems} from "../../../core/capp-V3.5-js-lib/src/js/components/CAPPRaceItems";
import {LazyImageLoader} from "../../../core/capp-V3.5-js-lib/src/js/util/LazyImageLoader";


class HomePageSlim extends CAPPLoggedOutPageBaseClass{
    constructor() {
        super();
    }

    init(){

        let images=document.querySelectorAll("capp-expand-section img");
        let imagesToLazyLoad=[];
        images.forEach(item=>{
            if(typeof item.dataset.src != "undefined"){
                imagesToLazyLoad.push(item);
            }
        })
        LazyImageLoader.addImagesToObserve(imagesToLazyLoad);
    }
}
new HomePageSlim();