import {LitElement, html} from "lit-element";
import {Helpers} from "../util/Helpers";
import {GameInfoSubview} from "./views/GameInfoSubview";

export class CAPPTopList extends LitElement{
    constructor() {
        super();
        this._data=null;
        this._deviceType=Helpers.getDeviceType();
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        this._data={};
        this._data.title=this.renderRoot.querySelector('section>h2').innerHTML;
        let linkEl=this.renderRoot.querySelector('section>a');
        this._data.allLink={
            text:linkEl.innerHTML,
            url:linkEl.href
        }

        let elements=this.renderRoot.querySelectorAll('.items a');
        let itemsData=[];
        elements.forEach((item,index)=>{
            if(index>=5){return;}
            let topListItem={
                title:item.querySelector('span').innerHTML,
                url:item.href,
                gameId:item.dataset.gameId,
                imageUrl:item.querySelector('img').dataset.src,
                loading:true
            }
            if(topListItem.imageUrl==""){topListItem.imageUrl=undefined;}
            itemsData.push(topListItem);
        })
        this._data.items=itemsData;

        console.log('top list data',this._data);
        super.connectedCallback();
    }

    firstUpdated(changedProperties) {
        this._initObserver();
        let images=this.renderRoot.querySelectorAll('.item-img img');
        if(this._imageObserver){
            images.forEach(image=>{
                this._imageObserver.observe(image);
            })
        }else{
            images.forEach(image=>{
                this._loadImage(image);
            })
        }
    }

    render(){
        return html`
            <section >
                <div class="item-section-header">
                    <h2>${this._data.title}</h2>
                    <a href="${this._data.allLink.url}">
                        ${this._data.allLink.text}
                        <img alt="Right Arrow Icon" src="${`${CDN_ASSETS_URL}/icons/right-arrow.svg`}" width="16" height="16">
                    </a>
                </div>
                
                <div class="items-list">
                    ${this._data.items.map((item,index)=>html`
                        <div>
                            <div class="num"><span>${index+1}</span></div>
                            <div class="item-img">
                                <a href="${item.url}" title="${item.title}" .item=${item} >
                                    <img data-src="${item.imageUrl}" alt="${item.title}" .item=${item}>
                                </a>    
                                ${item.loading?html`
                                    <capp-preloader></capp-preloader>
                                `:html`
                                    
                                `}
                                
                            </div>
                            <div class="title">
                                <a href="${item.url}" title="${item.title}" .item=${item} >
                                    <span>${item.title}</span>
                                </a>
                            </div>
                        </div>
                    `)}
                </div>
            </section>
        `;


    }

    _initObserver(){
        if("IntersectionObserver" in window){
            let imageObserver=new IntersectionObserver((entries, observer)=> {
                entries.forEach(entry=> {
                        if (entry.isIntersecting) {
                            this._loadImage(entry.target);
                        }
                    }
                );
            },{
                rootMargin: "0px 300px 300px 0px",
                threshold: 0.0
            });
            this._imageObserver=imageObserver;
        }
    }

    _loadImage(image){
        image.onLoad=()=>{
            this._setImageAsLoaded(image);
        };
        if(image.complete){
            this._setImageAsLoaded(image);
        }
        image.src = image.dataset.src;
    }

    _setImageAsLoaded(image){
        image.item.loading=false;
        this._imageObserver.unobserve(image);
        this.requestUpdate();
    }


    _openGameInfo(gameItem){
        let gameInfo=new GameInfoSubview(gameItem.gameId, gameItem.gameUrl);
        gameInfo.open();
    }



}
customElements.define('capp-top-list', CAPPTopList);