import {LitElement, html, css} from "lit-element";
import {APP_STRINGS} from "../core/AppStrings";

export class CAPPExpandSection extends LitElement {

    constructor() {
        super();
        this.expanded=false;

        APP_STRINGS.on("WPML_Ready",()=>{
            this.requestUpdate();
        })
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        this.titleElement=this.renderRoot.querySelector('h1');
        this.contentElement=this.renderRoot.querySelector('.content');
        this.moreContentElement=this.renderRoot.querySelector('.more-content');

        super.connectedCallback();
    }

    render() {
        return html`
            ${this.titleElement}
            ${this.contentElement}
            ${this.expanded?html`
                ${this.moreContentElement}
            `:''}
            <button class="text-link x-small text-link-group" @click=${this._onBtnClick}>
                <span>${this.expanded?APP_STRINGS.getWPMLString('ExpandSectionComponent-collapse'):APP_STRINGS.getWPMLString('ExpandSectionComponent-expand-label')}</span>
                ${this.expanded?html`
                    <img alt="Collapse Icon" src="${`${CDN_ASSETS_URL}/icons/up-chevron.svg`}" width="12" height="12">
                `:html`
                    <img alt="Expand Icon" src="${`${CDN_ASSETS_URL}/icons/down-chevron.svg`}" width="12" height="12">
                `}
                
            </button>
        `;
    }

    _onBtnClick(e){
        e.preventDefault();
        this.expanded=!this.expanded;
        this.requestUpdate();
    }


}

customElements.define('capp-expand-section', CAPPExpandSection);