import {SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {APP_STRINGS} from "../../core/AppStrings";


export class AddToHomeScreenSubView extends SubViewBase{
    constructor() {
        super();
    }

    renderViewContent() {
        return html`
            <div class="races-info-sub-wrap">
                <div class="ri-header">
                    <div class="controls">
                        <img class="close" alt="Close Button Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}" @click=${this.close}>
                    </div>
                    <h3>Install App?</h3>
                </div>
                <div class="content">
                    <section>
                        <img alt="Opt In Icon" src="${`${CDN_ASSETS_URL}/icons/opt-in.svg`}">
                        <h4>Emucasino</h4>
                        <h6>www.emucasino.com</h6>
                        <a class="btn-primary" @click=${this._onCTAClick}>Install</a>
                    </section>
                </div>
            </div>
        `;
    }


}

customElements.define("add-to-home-screen-sub",AddToHomeScreenSubView);

// window.showRI=function (){
//     let r=new RacesInfoSubview();
//     r.open();
// }