import {html, LitElement} from "lit-element";

export class CAPPRadio extends LitElement{

    static LAYOUT = {
        NORMAL:'normal',
        BOX:'box'
    }

    static get properties() {
        return {
            label: {type:String},
            name: {type:String},
            layout:{type:String},
            value:{type:String},
            checked:{type:Boolean}
        };
    }

    constructor() {
        super();
        this._layout=this.layout?this.layout:'normal';
        this._value=this.value;
    }

    get value(){
        let radios=this._getRadiosGroup();
        for(let i=0; i<radios.length; i++){
            if(radios[i].checked){
                return radios[i]._value;
            }
        }
        return null;
    }

    set value(val){
        this._value=val;
    }

    check(){
        let radios =this._getRadiosGroup();
        radios.forEach(item=>{
            item.renderRoot.querySelector('input').checked= (item==this);
            item._update();
        })
    }




    connectedCallback() {
        super.connectedCallback();
        this._layout=this.layout?this.layout:'normal';
    }



    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            <label class="${this._layout==CAPPRadio.LAYOUT.BOX?'radio-box':''}">
                ${this._layout==CAPPRadio.LAYOUT.BOX?html`<span>${this.label}</span>`:''}
                <span class="radio-display ${this.checked?'checked':''}"></span>
                <input type="radio" name="${this.name}" value="${this.value}" @change=${this._handleInputChange}>
                ${this._layout!=CAPPRadio.LAYOUT.BOX?html`<span>${this.label}</span>`:''}
            </label>
        `;
    }


    _handleInputChange(e){
        let radios =this._getRadiosGroup();
        radios.forEach(item=>{
            item._update();
        })
    }

    _getRadiosGroup(){
        let radios =document.body.querySelectorAll(`capp-radio[name="${this.name}"]`);
        return radios;
    }

    _update(){
        this.checked=this.renderRoot.querySelector('input').checked;
        this.requestUpdate();
    }


}
customElements.define('capp-radio',CAPPRadio);