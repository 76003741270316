import {LitElement, html} from "lit-element";

export class CAPPSiteNotification extends LitElement{
    constructor(message) {
        super();
        console.log(message, 'message');
        if(message!==undefined && message!=='' && sessionStorage.getItem('notificationClosed')!=='true'){
            this.message=message;
            const header = document.querySelector("header");

            if (header !== null) {
                //console.log(header.children[0]);
                header.insertBefore(this, (header.children[0] ? header.children[0] : null));
                this.renderRoot.style.display = "flex";
            }
        }
    }

    createRenderRoot() {
        return this;
    }

    render() {
        return html`
            <img class="icn" alt="Error Icon" src="${CDN_ASSETS_URL}/icons/error.svg">
            <p>${this.message}</p>
            <img class="icn close-btn" alt="Close Notification Button Icon" src="${CDN_ASSETS_URL}/icons/close.svg" @click=${this._closeNotification}>
        `;
    }

    _closeNotification(){
        this.parentElement.removeChild(this);
        sessionStorage.setItem('notificationClosed', "true");
    }
}

customElements.define('capp-site-notification',CAPPSiteNotification);