import {LitElement, html} from "lit-element";
import {Helpers} from "../util/Helpers";
import {LazyImageLoader} from "../util/LazyImageLoader";
import {GameInfoSubview} from "./views/GameInfoSubview";

export class CAPPGameTile extends LitElement{

    static MODE = {
        ONE_ROW:'oneRow',
        TWO_ROWS:'twoRows'
    }

    static get properties() {
        return {
            mode: {type:String}
        };
    }

    constructor() {
        super();

        this._data= {};
        this._deviceType=Helpers.getDeviceType();

        this._gamesHolder=null;
        this._gamesElement=null;

        this._pageWidth=0;
        this._currentPage=0;
        this._numberOfPages=0;
        this._imageObserver=null;
        this._platform=Helpers.getDeviceType();

        this._showLeftArrow=false;
        this._showRightArrow=true;
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        this._data.title=this.renderRoot.querySelector('section>h2').innerHTML;
        let linkEl=this.renderRoot.querySelector('section>a');

        if(linkEl){
            this._data.allGameslink={
                text:linkEl.innerHTML,
                url:linkEl.href,
                title:linkEl.title,
                category:linkEl.dataset.category
            }
        }

        this._data.games=this._collectGamesData();
        console.log(this._data.games, 'connectedCallback - games data');
        super.connectedCallback();
    }

    _collectGamesData(){
        let gameElements=this.renderRoot.querySelectorAll('.games a');
        let gamesData=[];
        if(gameElements){
            gameElements.forEach(item=>{
                let gameItem={
                    title:item.title,
                    gameUrl:item.href,
                    gameId:item.dataset.gameId,
                    isBig:item.classList.contains('big'),
                    img:item.querySelector('img').dataset.src,
                    loading:true,
                    category:item.dataset.category,
                    eventLogo: item.dataset.eventLogo ? item.dataset.eventLogo : null
                };
                gameItem.imageBaseName=gameItem.img;
                gameItem.imageBaseName=gameItem.imageBaseName.replace('.jpg','');
                gameItem.imageBaseName=gameItem.imageBaseName.replace('.png','');
                gamesData.push(gameItem);
            })
        }
        return gamesData;
    }

    _loadGamesData(){
        //Do nothing - By default the games are loaded from PHP rendered page instead of API
    }

    _setGamesData(gamesData){
        try {
            if(gamesData){
                this._data.games=[];
                gamesData.forEach(item=>{
                    console.log(item);
                    let gameItem={
                        title:item.shortTitle,
                        gameUrl:item.gameLink,
                        gameId:item.gameId,
                        isBig:false,
                        img:item.lobbyThumbnailUrl,
                        //loading:true,
                        category:item.gameCategory,
                        eventLogo:null
                    };

                    //CAPP v3.5 - homepage hero and game thumbnails using picture element
                    gameItem.imageBaseName=gameItem.img;
                    gameItem.imageBaseName=gameItem.imageBaseName.replace('.jpg','');
                    gameItem.imageBaseName=gameItem.imageBaseName.replace('.png','');
                    this._data.games.push(gameItem);
                });
                console.log(this._data.games);

                if(this._data.games.length > 0){
                    this.renderRoot.style.display = 'block';
                    this.requestUpdate().then(()=>{
                        this._initializeComponentControls();
                    });
                }
            }
        }catch (e){
            console.log(e);
        }
    }

    firstUpdated(changedProperties) {
        console.log(this._data.games, 'firstUpdated - games data');
        if(this._data.games.length>0){
            this._initializeComponentControls();
        }
        else{
            this._loadGamesData();
        }
    }

    render(){
        return this._data.games.length > 0 ? html`
        <section class="${this.mode==CAPPGameTile.MODE.ONE_ROW?'one-row':''}">
            <div class="item-section-header">
                <h2>${this._data.title}</h2>
                ${this._data.allGameslink?html`
                    <a href="${this._data.allGameslink.url}">
                        ${this._data.allGameslink.text}
                        <img alt="Right Arrow Icon" src="${`${CDN_ASSETS_URL}/icons/right-arrow.svg`}" width="16" height="16">
                    </a>
                `:''}
            </div>
            <div class="games ${this._deviceType!='desktop'?'scrolling':'desktop-scrolling'}">
                <div class="games-holder">
                    ${this._renderGameItems()}
                </div>
            </div>
            ${this._deviceType=='desktop'?html`
                    ${this._showLeftArrow?html`
                        <div class="g-btn btn-prev" @click=${this._previousPageBtnClick}><img alt="Left Arrow Icon" src="${`${CDN_ASSETS_URL}/icons/left-arrow.svg`}" width="32" height="32"></div>
                    `:''}
                    ${this._showRightArrow?html`
                        <div class="g-btn btn-next" @click=${this._nextPageBtnClick}><img alt="Right Arrow Icon" src="${`${CDN_ASSETS_URL}/icons/right-arrow.svg`}" width="32" height="32"></div>
                    `:''}
                    
                `:''}
        </section>
        ` : '';
    }

    _renderGameItems() {
        return html`
            ${this._data.games.map(item => html`
                <div class="game-item ${item.isBig ? 'big' : ''} ${item.loading ? 'loading' : 'loaded'}">
                    <a href="${item.gameUrl}" title="${item.title} ${item.category}" .item=${item} @click=${this._registerPage}>
                        <picture>
                            ${item.isBig!=true && item.error!=true ? html`
                                <source data-srcset="${this._getImageUrl(item,'webp','small')}" media="(max-width: 720px)" />
                                <source data-srcset="${this._getImageUrl(item,'jpeg','small')}" media="(max-width: 720px)" />
                                <source data-srcset="${this._getImageUrl(item,'webp','medium')}" media="(min-width: 720px)" />
                                <source data-srcset="${this._getImageUrl(item,'jpeg','medium')}" media="(min-width: 720px)" />
                            `:``}

                            <img 
                                 data-src="${this._getImageUrl(item,'jpeg','big')}"   
                                 title="${item.title || ''}"
                                 alt="${item.title + " " + item.category || ''}"
                                 .item=${item}>
                        </picture>
                        <h2 class="game-title">${item.title} 
                           ${this._renderBottomLabel(item)}
                        </h2>
                        ${item.eventLogo && item.eventLogo !== ''?html`
                            <img src="${item.eventLogo}" class="tiles-game-event-logo">
                        `:''}
                    </a>
                    <div class="game-overlay">
                        ${item.loading ? html`
                            <capp-preloader></capp-preloader>
                        ` : html`
                            <img class="play-ic" alt="Play Icon" src="${`${CDN_ASSETS_URL}/icons/play.svg`}" width="40" height="40">
                            ${this._platform != "mobile" ? html`
                                <img class="info-ic" alt="Game Info Icon" src="${`${CDN_ASSETS_URL}/icons/info.svg`}" .item=${item}
                                     @click=${this._onGameInfoClick} width="20" height="20">
                            ` : ''}
                        `}
                    </div>
                </div>
            `)}
        `;
    }

    _registerPage() {
        window.sessionStorage.setItem('isNotSingleGamePage', location.href);
    }

    _getImageUrl(item,type,size){
        let w,h;
        switch (size){
            case "small":
                w=180;
                h=120;
                break;
            case "medium":
                w=216;
                h=144;
                break;
            default:
                w=348;
                h=232;

        }

        let url= item.img;
        if (window.isCFCDNEnabled) { url=`${window.CFResizerURL}/width=${w},height=${h},quality=90,format=${type}/${item.img}`; }
        return url;
    }

    _renderBottomLabel (item) {
        return  html `<span class="game-category">${item.category}</span>`;
    }

    _initializeComponentControls() {
        this._gamesElement=this.renderRoot.querySelector('.games');

        this._gamesHolder=this.renderRoot.querySelector('.games-holder');
        this._updatePagingData();
        window.addEventListener('resize',()=>{
            this._updatePagingData();
            this._scrollToCurrentPage();
        });

        if(this._deviceType=='desktop'){
            this._gamesElement.addEventListener('scroll',()=>{
                console.log('scrolling2');
                this._updatePagingData();
                if(this._gamesElement.scrollLeft>0){
                    this._showLeftArrow=true;
                }else{
                    this._showLeftArrow=false;
                }
                if(this._gamesElement.scrollLeft+this._gamesElement.offsetWidth>=this._gamesElement.scrollWidth){
                    this._showRightArrow=false;
                }else{
                    this._showRightArrow=true;
                }

                this._currentPage=Math.floor(this._gamesElement.scrollLeft/this._pageWidth);
            })
        }

        this._initObserver();
        let images=this.renderRoot.querySelectorAll('.game-item a img:not(.tiles-game-event-logo)');
        if(this._imageObserver){
            images.forEach(image=>{
                this._imageObserver.observe(image);
            })
        }else{
            images.forEach(image=>{
                this._loadImage(image);
            })
        }
    }

    _initObserver(){
        if("IntersectionObserver" in window){
            let imageObserver=new IntersectionObserver((entries, observer)=> {
                entries.forEach(entry=> {
                        if (entry.isIntersecting) {
                            this._loadImage(entry.target);
                        }
                    }
                );
            },{
                rootMargin: "0px 300px 300px 0px",
                threshold: 0.0
            });
            this._imageObserver=imageObserver;
        }
    }

    _loadImage(image){
        image.onLoad=()=>{
            this._setImageAsLoaded(image);
        };

        image.onerror=()=>{
            image.item.error = true;
            image.src = image.item.img;
            image.onerror=  false;
            this._setImageAsLoaded(image);
        };


        if(image.complete){
            this._setImageAsLoaded(image);
        }

        //if parent is picture, setup source srcset attribute
        if(image.parentElement.nodeName.toLowerCase()=="picture"){
            let sourceElements=image.parentElement.querySelectorAll('source');
            sourceElements.forEach(srcEl=>{
                srcEl.srcset=srcEl.dataset.srcset;
            })
        }

        image.src = image.dataset.src;


    }

    _setImageAsLoaded(image){
        image.item.loading=false;
        this._imageObserver.unobserve(image);
        this.requestUpdate();
    }

    _updatePagingData(){
        let gameItem=this._gamesHolder.querySelector('.game-item:not(.big)');

        if(gameItem != null){
            let gameItemWidth=gameItem.offsetWidth+8;
            let windowWidth=this._gamesElement.offsetWidth;
            this._pageWidth=Math.floor(windowWidth/gameItemWidth)*gameItemWidth;
            this._numberOfPages=Math.ceil(this._gamesHolder.offsetWidth/this._pageWidth);
            this.requestUpdate();
        }
    }

    _previousPageBtnClick(){
        this._currentPage--;
        this._scrollToCurrentPage();

    }
    _nextPageBtnClick(){
        this._currentPage++;
        this._scrollToCurrentPage();
    }

    _scrollToCurrentPage(){
        if(this._currentPage<0){
            this._currentPage=0;
        }
        if(this._currentPage>=this._numberOfPages){
            this._currentPage=this._numberOfPages-1;
        }
        this.requestUpdate();
        let scrollPos=this._currentPage*this._pageWidth;
        this._gamesElement.scrollTo({
            top:0,
            left:scrollPos,
            behavior: 'smooth'
        })
    }

    _onGameInfoClick(e){
        this._openGameInfo(e.target.item);
    }

    _openGameInfo(gameItem){
        let gameInfo=new GameInfoSubview(gameItem.gameId, gameItem.gameUrl);
        gameInfo.open();
    }
}

customElements.define('capp-game-tile', CAPPGameTile);