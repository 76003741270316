import {CAPPPageBaseAbstractClass} from "./CAPPPageBaseAbstractClass";
import {SubViewManager} from "../../../../core/capp-V3.5-js-lib/src/js/controllers/SubViewManager";
import {USER} from "../../../../core/capp-V3.5-js-lib/src/js/core/User";
import {CAPP_ROUTER} from "../core/CAPPRouter";
import {LoginSubview} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/LoginSubview";
import {RegistrationSubview} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/RegistrationSubview";
import {ResetPasswordSubView} from "../../../../core/capp-V3.5-js-lib/src/js/components/views/ResetPasswordSubView";

import {CAPPNavigation} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPNavigation";
import {CAPPProgressBar} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPProgressBar";
import {CAPPExpandSection} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPExpandSection";
import {CAPPExpandSectionWithImages} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPExpandSectionWithImages";
import {CAPPBanner} from "../../../../core/capp-V3.5-js-lib/src/js/components/CAPPBanner";


export class CAPPLoggedOutPageBaseClass extends CAPPPageBaseAbstractClass{
    constructor() {
        super();
    }

    _handleRouteChange(routeData){
        console.log('***handle route change', routeData);
        if(routeData==null){
            SubViewManager.closeCurrentSubview();
            return;
        }

        switch (routeData.action){
            case "login":
                if(USER.isLoggedIn){
                    CAPP_ROUTER.setRoute("");
                }else{
                    let login=new LoginSubview();
                    login.open();
                }
                break;
            case "signup":
                if(USER.isLoggedIn){
                    CAPP_ROUTER.setRoute("#profile");
                }else{
                    let reg=new RegistrationSubview();
                    reg.open();
                }
                break;
            case "resetPassword":
                let reset=new ResetPasswordSubView();
                reset.open();
                break;
            case "profile":
            case "personal-details":
            case "verification":
            case "deposit":
            case "withdraw":
            case "history":
            case "limits":
            case "bonuses":
            case "inbox":
            case "claim":
                let login=new LoginSubview();
                login.open();
                break;
            default:
                CAPP_ROUTER.setRoute("");
                SubViewManager.closeCurrentSubview();
        }
    }


}