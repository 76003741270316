import {html} from "lit-element";
import {FormElementBase} from "./FormElementBase";

export class CAPPText extends FormElementBase{

    static get properties() {
        return {
            value: {type:String},
            placeholder: {type:String},
            disabled:{type:Boolean}
        };
    }

    constructor() {
        super();
        this._inputEl=null;
        this._value=this.value?this.value:"";
    }

    set value(val){
        this._value=val;
        this.requestUpdate();
    }

    get value(){
        return this._value;
    }



    firstUpdated(changedProperties) {
        this._inputEl=this.renderRoot.querySelector('textarea');
        this._inputEl.addEventListener('change',()=>{
            this._value=this._inputEl.value;
        })
    }

    _renderInput() {
        return html`
            <div class="input-wrap">
                <textarea name="${this.name}" placeholder="${this.placeholder?this.placeholder:''}" 
                          spellcheck="false"
                          .value="${this.value?this.value:''}"
                          ?disabled=${this.disabled}
                ></textarea>
            </div>
        `;
    }
}

customElements.define('capp-text',CAPPText);