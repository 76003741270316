import {SUB_VIEW_POSITION, SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {APP_STRINGS} from "../../core/AppStrings";
import {LanguageMenuSubview} from "./LanguageMenuSubview";

export class MenuSubview extends SubViewBase{
    constructor(menuItems,languageSubMenuitems) {
        super();
        this._subViewPosition=SUB_VIEW_POSITION.LEFT;
        this._menuItems=menuItems;
        this._languageSubMenuitems=languageSubMenuitems;

    }

    renderViewContent() {
        return html`
            <div class="menu-sub">
                <div class="menu-header">
                    <img class="close-btn" alt="Close Menu Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}" @click=${this._onBackButtonClick} width="24" height="24">
                        <!--<img class="close-btn" src="${`${CDN_ASSETS_URL}/icons/left-arrow.svg`}" @click=${this._onBackButtonClick}>-->
                    <h3>${APP_STRINGS.getWPMLString('MenuSubView-title')}</h3>
                </div>
                <div class="menu-items">
                    ${this._menuItems.map(item=>html`
                        <a href="${item.link}">
                            <img class="icon" alt="${item.label} Icon" src="${item.icon}" width="24" height="24">
                            <span>${item.label}</span>
                            <img class="arrow" alt="Arrow Icon" src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}" width="16" height="16">
                        </a>
                    `)}
                    ${this._languageSubMenuitems.length>0? html`<a  data-action="language" class="language" @click=${this._handleButtonClick}> <img alt="LanguageIcon" class="icon" src="${`${CDN_ASSETS_URL}/icons/language.svg`}" width="20" height="20"> <span>${APP_STRINGS.getWPMLString('LanguageMenuSubView-title')}</span>
                    <img class="arrow" alt="Arrow Icon" src="${`${CDN_ASSETS_URL}/icons/right-chevron.svg`}" width="16" height="16">
                    </a>`:''}
                   
                </div>
            </div>
        `;
    }

    _onBackButtonClick(){
        this.close();
    }

    _handleButtonClick(e){
        console.log('do action',e.currentTarget.dataset.action);
        //todo implement launch actions
        switch (e.currentTarget.dataset.action){
           
            case 'language':
                let languageMenu = new LanguageMenuSubview(this._languageSubMenuitems,this._menuItems, this._subViewPosition);
                languageMenu.open();
                break;               

        }
    }



}

customElements.define('menu-sub',MenuSubview);