import {LitElement, html} from "lit-element";
import 'swipe-touch-events';
import {Helpers} from "../util/Helpers";
import {USER} from "../core/User";
import {GAHelpers} from "../util/GAHelpers";

const HERO_SLIDE_INIT_EVENTS = ['mousedown', 'mousemove', 'scroll', 'keydown', 'touchstart', 'touchmove']; //Events that triggers the chat widget to start

export class CAPPHero extends LitElement{

    static MODE = {
        AUTOMATIC:'automatic',
        MANUAL:'manual',
        EVENTS_INIT_AUTOMATIC:'events_init_automatic' //#21825 - SEO and Site Optimization - Only initialize hero banners slide animation in 'automatic' mode when there is an user event triggered(from HERO_SLIDE_INIT_EVENTS)
    }

    static SLIDE_TYPE = {
        LOGGED_IN:'logged-in',
        LOGGED_OUT:'logged-out'
    }

    static DEFAULT_SLIDE_TIME=8;

    static get properties() {
        return {
            mode: {type:String}
        };
    }


    constructor() {
        super();
        this._pictureSourceConfig = [];
        this._slideInitEvents = [];
        this._allCollectedSlides=[];
        this._slides=[];
        this._currentSlide=0;
        this._previousSlide=0;
        this._currentTimeout=null;
        this.loginState=false;
        this._loadAllImages=false; // true = load all banners images on page load, false = only load the 1st banner image on page load
        this._applyInitialMode();
        this._loadPictureSourceConfig();
    }
    createRenderRoot() {
        return this;
    }

    connectedCallback() {

        this._collectSlideData();
        this._filterSlidesByUserLoggedInState();

        this._slides[0].layer=2;

        if(Helpers.getDeviceType()!='desktop' && this._slides.length>1){
            this._initSwipeEvents();
        }

        USER.addListener("onLoginStateChange",e=>{
            this._resetHeroSlider();
        });
        super.connectedCallback();
    }

    firstUpdated(changedProperties) {
        this._initHeroSlider();

    }

    render(){
        return html`
            <div class="slides-viewport">
                    ${this._slides.map((slide,index)=>html`
                        <div class="slide slide-${index} ${this._currentSlide==index?`active ${slide.animate}`:''} " style="z-index: ${slide.layer};">
                            ${slide.image?html`
                                ${index>0 && this._loadAllImages==false ? html``: this._getPictureContent(slide, index)}
                            `:''}
                            <div class="content">
                                ${slide.textContent}
                                <a class="btn-primary" href="${slide.cta.url}" @click=${this._onCTAClick}>${slide.cta.label}</a>
                            </div>
                        </div>
                    `)}
            </div>
            ${this._slides.length>1?html`
                <div class="slide-nav">
                    ${this._slides.map((slide,index)=>html`
                        <div class="slide-btn ${this._currentSlide==index?'active':''}" data-slide-index="${index}" @click=${this._onSlideButtonClick}></div>
                    `)}
                </div>
            `:``}
        `;
    }
    
    _loadPictureSourceConfig(){
        const sourceElements=this.renderRoot.querySelectorAll('div.hero-banner source, div.hero-banner img');
        sourceElements.forEach(el=>{
            let source = {};
            if(el.srcset){
                source.src=el.srcset;
                source.media=el.media;
            }
            else if(el.src){
                source.src=el.src;
                source.media=undefined;
            }

            if(source.src){
                if(source.src.split("/https://")[1]){
                    source.srcPrefix=source.src.split("/https://")[0]+'/';
                }
                else{
                    source.srcPrefix='';
                }
                this._pictureSourceConfig.push(source);
            }
        })
    }

    _getPictureContent(banner, index){
        console.log(this._pictureSourceConfig, '_pictureSourceConfig');

        if(this._pictureSourceConfig.length > 0){
            return banner !== null && banner.image ? html`
                <picture class="slide-image">
                    ${this._pictureSourceConfig.map((source,index)=>html`
                        ${source.media ? html`
                        <source srcset="${source.srcPrefix}${banner.image}" media="${source.media}" />
                        `:html`
                        <img class="hero-img" alt="Hero Banner Image ${index}" src="${source.srcPrefix}${banner.image}">
                        `}
                    `)}
                </picture>
            ` : '';
        }
        else{
            return html`
                <picture class="slide-image">
                    <source srcset="${this._getImageUrl(slide.image,'webp','small')}" media="(max-width: 720px)" />
                    <source srcset="${this._getImageUrl(slide.image,'jpeg','small')}" media="(max-width: 720px)" />
                    <source srcset="${this._getImageUrl(slide.image,'webp','big')}" media="(min-width: 720px)" />
                    <img alt="Hero Banner Image ${index}" src="${slide.image}">  
                </picture>
            `;
        }
    }

    _getImageUrl(imgUrl,type,size){
        let w,h;
        switch (size){
            case "small":
                w=404;
                h=280;
                break;
            default:
                w=1440;
                h=456;

        }
        let url = imgUrl;
        if (window.isCFCDNEnabled) { url=`${window.CFResizerURL}/width=${w},height=${h},fit=crop,quality=80,format=${type}/${imgUrl}`; }

        return url;
    }

    _collectSlideData(){
        let slideElements=this.renderRoot.querySelectorAll('div.hero-banner');
        slideElements.forEach(el=>{
            let slide={};
            slide.textContent=el.querySelectorAll('span,.mid-text');
            let link=el.querySelector('a');
            slide.cta={
                url:link.href,
                label:link.innerText
            };
            slide.animate='';
            slide.layer=0;
            slide.image=el.querySelector('img');
            slide.image=slide.image.src ? slide.image.src : slide.image.dataset.src;

            if(slide.image && slide.image.split("/https://")[1]){
                slide.image='https://'+slide.image.split("/https://")[1];
            }

            slide.type=el.dataset.type;
            if(slide.image==""){slide.image=undefined;}

            let timeVal=el.dataset.slideTime;
            slide.time= timeVal?parseFloat(timeVal):CAPPHero.DEFAULT_SLIDE_TIME;

            this._allCollectedSlides.push(slide);

        })
    }

    _filterSlidesByUserLoggedInState(){
        this._slides=[];
        let rejectType=USER.isLoggedIn?CAPPHero.SLIDE_TYPE.LOGGED_OUT:CAPPHero.SLIDE_TYPE.LOGGED_IN;

        this._allCollectedSlides.forEach(slide=>{
            if(slide.type==rejectType){return;}
            this._slides.push(slide);
        })
    }

    _initSwipeEvents(){
        this.renderRoot.addEventListener('swiperight', () => {
            this._prevSlide();
        });

        this.renderRoot.addEventListener('swipeleft', () => {
            this._nextSlide();
        });
    }

    _setLoadAllImages(){
        if(this._loadAllImages === false && this._slides.length > 1){
            console.log(this._slides[this._currentSlide]);

            if(this.mode===CAPPHero.MODE.EVENTS_INIT_AUTOMATIC){
                this._loadAllImages=true;
                this.requestUpdate();
            }else{
                let loadDelay;
                if(this._slides[this._currentSlide].time){
                    loadDelay=Math.max(1,this._slides[this._currentSlide].time-1);
                }else{
                    loadDelay=1;
                }

                setTimeout(()=>{
                    this._loadAllImages=true;
                    this.requestUpdate();
                },loadDelay*1000);
            }
        }
    }

    _initHeroSlider(){
        if(this._slides.length>1){
            if(this.mode==CAPPHero.MODE.AUTOMATIC || this.mode==CAPPHero.MODE.EVENTS_INIT_AUTOMATIC){
                console.log(this._slideInitEvents);
                if(this.mode==CAPPHero.MODE.EVENTS_INIT_AUTOMATIC){
                    if(this._slideInitEvents.length===0){ //No active event so can proceed to call addEventListener
                        this._slideInitEvents = HERO_SLIDE_INIT_EVENTS;
                        // bind causes a fixed `this` context to be assigned to `_scheduleNextSlide`
                        this._scheduleNextSlide = this._scheduleNextSlide.bind(this);

                        this._slideInitEvents.forEach(key => {
                            console.log(key, 'addEventListener');
                            document.addEventListener(key, this._scheduleNextSlide);
                        });
                    }
                }
                else{
                    this._scheduleNextSlide();
                }
            }

            if(this.mode!==CAPPHero.MODE.EVENTS_INIT_AUTOMATIC){
                this._setLoadAllImages();
            }
        }
    }

    _resetHeroSlider(){
        if(this._currentTimeout){
            clearTimeout(this._currentTimeout);
        }

        this._filterSlidesByUserLoggedInState();
        this._currentSlide=0;
        this._previousSlide=0;
        this._slides.forEach(slide=>{
            slide.layer=0;
            slide.animate='';
        })
        this._slides[0].layer=2;


        this.requestUpdate();
        this._initHeroSlider();
    }

    _onSlideButtonClick(e){
        if(this._loadAllImages==false){
            this._loadAllImages=true;
        }

        this._moveToSlide(parseInt(e.target.dataset.slideIndex));
        if(this.mode==CAPPHero.MODE.AUTOMATIC || this.mode==CAPPHero.MODE.EVENTS_INIT_AUTOMATIC){
            this._scheduleNextSlide();
        }

    }

    _moveToSlide(index,forceAnimation){
        if(this._currentSlide==index){return;}

        let anim="animate-r";
        if(forceAnimation){
            anim=forceAnimation;
        }else if(this._currentSlide>index){
            anim='animate-l';
        }

        this._slides.forEach(slide=>{
            slide.layer=0;
            slide.animate='';
        })
        this._slides[this._currentSlide].layer=1;

        this._currentSlide=index;
        this._slides[this._currentSlide].layer=2;

        this._slides[this._currentSlide].animate=anim;

        this.requestUpdate();
    }

    _nextSlide(){
        let index=this._currentSlide+1;
        if(index>=this._slides.length){
            index=0;
        }
        this._moveToSlide(index,'animate-r');
    }

    _prevSlide(){
        let index=this._currentSlide-1;
        if(index<0){
            index=this._slides.length-1;
        }
        this._moveToSlide(index,'animate-l');
    }

    _scheduleNextSlide(){
        if(this.mode===CAPPHero.MODE.EVENTS_INIT_AUTOMATIC){
            this._setLoadAllImages();

            if(this._slideInitEvents.length > 0){
                this._slideInitEvents.forEach(key => {
                    console.log(key, 'removeEventListener');
                    document.removeEventListener(key, this._scheduleNextSlide);
                });
                this._slideInitEvents = [];
            }
        }

        if(this._currentTimeout){
            clearTimeout(this._currentTimeout);
        }
        this._currentTimeout=setTimeout(()=>{
            this._nextSlide();
            this._scheduleNextSlide();
        },this._slides[this._currentSlide].time*1000);

    }

    _applyInitialMode(){
        switch (this.mode){
            case CAPPHero.MODE.MANUAL:
            case CAPPHero.MODE.AUTOMATIC:
            case CAPPHero.MODE.EVENTS_INIT_AUTOMATIC:
                break;
            default:
                this.mode=CAPPHero.MODE.EVENTS_INIT_AUTOMATIC;
        }
    }

    _onCTAClick(){
        GAHelpers.gaEvent('HeroBanner', 'ctaClick');
        window.sessionStorage.setItem('isNotSingleGamePage', location.href);
    }



}

customElements.define('capp-hero', CAPPHero);