import {html} from "lit-element";
import {APP_STRINGS} from "../core/AppStrings";
import {AddToHomeScreenSubView} from "./views/AddToHomeScreenSubView";
import { CAPPSiteNotification } from "./CAPPSiteNotification";

export class CAPPAddToHomeScreen extends CAPPSiteNotification{
    constructor() {
        super();

        const header = document.querySelector("header");
        if (header !== null) {
            //console.log(header.children[0]);
            header.insertBefore(this, (header.children[0] ? header.children[0] : null));
            this.renderRoot.style.display = "flex";
        }
    }

    render() {
        return html`
            <a class="logo small" href="">
                <img id="logo-small" 
                    alt="emucasino-mobile-site-logo" 
                    src="${`${CDN_IMAGES_BRAND_ASSETS_URL}/logos/logo-brandmark.svg`}" 
                    width="27" 
                    height="23" >
            </a>
            <p>${APP_STRINGS.getWPMLString('AddToHomeScreen-Title-text')} Install and play</p>
            <a class="btn-primary" @click=${this._onCTAClick}>Install App</a>
            <img class="icn close-btn" 
                alt="Close Notification Button Icon" 
                src="${CDN_ASSETS_URL}/icons/close.svg" 
                @click=${this._closeNotification}>
        `;
    }

    _onCTAClick() {
        let addToHomeScreenSub=new AddToHomeScreenSubView();
        addToHomeScreenSub.open();
    }

}

customElements.define('capp-add-to-home-screen',CAPPAddToHomeScreen);