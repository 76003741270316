import {LitElement, html} from "lit-element";
import {APP_STRINGS} from "../core/AppStrings";
import {CAPP_API} from "../core/CAPPApi";
import {unsafeHTML} from "lit-html/directives/unsafe-html";
import {CURRENCY_HELPERS} from "../util/CurrencyHelpers";

export class CAPPNewsFeed extends LitElement{

     static  MAX_ITEMS = 200;
     static ICONS_BASE_NAME=`${CDN_ASSETS_URL}/ec-winner-feed-icon-32x32-`;

    constructor() {
        super();
        this._data=[];
        this._error=null;
        this._loading=true;
        this._feedScrollingPositions=null;
        this._currentItem=0;
        this._initLoadDone=false;
        this._initEvents = ['mousedown', 'mousemove', 'scroll', 'keydown', 'touchstart', 'touchmove']; //Events that triggers the news feed component to start
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        APP_STRINGS.on("WPML_Ready",()=>{
            // bind causes a fixed `this` context to be assigned to `_loadData`
            this._loadData = this._loadData.bind(this);
            this._initEvents.forEach(key => {
                console.log(key, 'addEventListener');
                document.addEventListener(key, this._loadData);
            });
        });

        const resizeObserver = new ResizeObserver((entries) => {
            if(this._initLoadDone){
               this._calculateFeedItemPositions();
               this._moveToSlide(this._currentItem);

            }
        });
        resizeObserver.observe(this);

        super.connectedCallback();
    }

    render(){
        return html`
            <div class="news-feed-wrap">
                ${this._loading && this._initLoadDone==false ? html`
                    <capp-preloader></capp-preloader>
                `:html`
                    ${this._error?html`
                    <div class="error-message">
                        <img class="icon" alt="Warning Icon" src="${`${CDN_ASSETS_URL}/icons/warning.svg`}">
                        <span>${this._error}</span>
                    </div>
                    `:html`
                        ${this._renderFeed()}
                    `}
                `}
                
                
            </div>
        `;
    }

    _renderFeed(){
        return html`
            <div class="feed">
                ${this._data.map(item=>html`
                    <a href="${item.gameUrl}" target="_blank">
                        <div class="feed-item">
                            <div class="img-placeholder">
                                ${this._renderRandomIcon()}
                            </div>
                            <p> ${unsafeHTML(item.text)}</p>
                        </div>
                    </a>
                    
                `)}
            </div>
        `;
    }

    _renderRandomIcon(){
        let randNum=Math.min(Math.floor(Math.random()*8)+1,8);
        let iconBase=`${CAPPNewsFeed.ICONS_BASE_NAME}0${randNum}`;
        return html`
            <picture>
                <source srcset="${iconBase}.webp"/>
                <img alt="Feed Item Image" src="${iconBase}.jpg" width="32" height="32">
            </picture>
        `;
    }

    _showLoader(){
        const preloader = this.renderRoot.querySelector('capp-preloader');
        if(preloader){
            preloader.classList.add('show');
        }
    }

    _loadData(){
        this._initEvents.forEach(key => {
            console.log(key, 'removeEventListener');
            document.removeEventListener(key, this._loadData);
        });

        //To start showing the loading...
        this._showLoader();

        CAPP_API.getWinnersFeed(120,100, 10).then((data)=>{
            this._data=this._processFeedData(data.feedData);
            this._loading=false;

            if (!this._data || this._data.length === 0) {
                const newsFeedWrap = this.renderRoot.querySelector('.news-feed-wrap');
                if(newsFeedWrap){
                    newsFeedWrap.classList.add('no-data');
                }
            }

            this.requestUpdate().then(()=>{
                if(this._error==null){
                    this._initFeedScrolling();
                }
                this._initLoadDone=true;
            });

        }).catch(e=>{
            this._loading=false;
            this._initLoadDone=true;
            this._error="Error loading feed data";
            this.requestUpdate();
        });


    }



    _processFeedData(feedData){
        let data=[];
        let betString,wonString;


        feedData.forEach(item=>{

            if(CURRENCY_HELPERS.getCurrencySymbolPlacement()=="left"){
                betString=`${item.win.currency.symbol}${item.bet.amount}`;
                wonString=`${item.win.currency.symbol}${item.win.amount}`;
            }else{
                betString=`${item.bet.amount}${CURRENCY_HELPERS.getRightAlignedCurrencySymbolPrefix()}${item.win.currency.symbol}`;
                wonString=`${item.win.amount}${CURRENCY_HELPERS.getRightAlignedCurrencySymbolPrefix()}${item.win.currency.symbol}`;
            }

            data.push({
                text:`${item.player.firstname} bet ${betString} and won ${wonString} (Win Multiplier: ${item.winMultiplier}) on ${item.game.displayName}`,
                image:item.game.thumbnail_cdn,
                gameUrl:item.game.game_link
            })
        })
        return data;
    }

    _initFeedScrolling(){
        this._calculateFeedItemPositions();

        if(!this._initLoadDone){
            this._scheduleNextPosition();
        }

    }

    _calculateFeedItemPositions(){
        this._feedScrollingPositions=[];
        let feedItems=this.renderRoot.querySelectorAll('.feed-item');
        feedItems.forEach(item=>{
            this._feedScrollingPositions.push(item.offsetLeft);
        })
    }

    _scheduleNextPosition(){
        setTimeout(()=>{
            this._moveToNextSlide();
        },5000);
    }

    _moveToNextSlide(){
        this._currentItem++;
        if(this._currentItem>=this._feedScrollingPositions.length){return;}

        let newPosition=this._moveToSlide(this._currentItem);
        let feedElement=this.querySelector('.feed');


        let tmp=feedElement.offsetWidth+newPosition-(this.offsetWidth*2);

        //repeat if all seen
        if(tmp<=0){
            this._data=this._data.concat(this._data);
            this.requestUpdate().then(()=>{
                this._initFeedScrolling();
            });

        }

        this._scheduleNextPosition();
    }

    _moveToSlide(index){
        let newPosition=-this._feedScrollingPositions[index];
        let feedElement=this.querySelector('.feed');
        feedElement.style.transform=`translateX(${newPosition}px)`;
        return newPosition;
    }

    _resetFeedPosition(){
        this._currentItem=0;
        let feedElement=this.querySelector('.feed');
        feedElement.style.transform=`translateX(0px)`;
    }


}

customElements.define('capp-news-feed', CAPPNewsFeed);