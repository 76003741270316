import {CAPPGameTile} from "./CAPPGameTile";
import {html} from "lit-element";
import {USER} from "../core/User";
import {GameInfoSubview} from "./views/GameInfoSubview";
import {Helpers} from "../util/Helpers";
import {CAPP_API} from "../core/CAPPApi";
import {CURRENCY_HELPERS} from "../util/CurrencyHelpers";

export class CAPPGameTileRecommendedGames extends CAPPGameTile{
    constructor() {
        super();
        this.mode=CAPPGameTile.MODE.ONE_ROW;
        this._platform=Helpers.getDeviceType();
    }

    _loadGamesData(){
        if(USER.isLoggedIn){
            CAPP_API.getRecommendedGames(USER.sessionKey).then(data=>{
                console.log(data);
                if(data.error){
                    console.log(data.error);
                    return;
                }else{
                    this._setGamesData(data.games);
                }
            })
        }
    }
}

customElements.define('capp-game-tile-recommended-games', CAPPGameTileRecommendedGames);