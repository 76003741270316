import {CAPPGameTile} from "./CAPPGameTile";
import {html} from "lit-element";
import {GameInfoSubview} from "./views/GameInfoSubview";
import {Helpers} from "../util/Helpers";
import {CAPP_API} from "../core/CAPPApi";
import {CURRENCY_HELPERS} from "../util/CurrencyHelpers";

export class CAPPGameTileBigWinners extends CAPPGameTile {
    constructor() {
        super();
        this.mode=CAPPGameTile.MODE.TWO_ROW;
        this._platform=Helpers.getDeviceType();
    }

    _collectGamesData(){
        let gameElements=this.renderRoot.querySelectorAll('.games a');
        let gamesData=[];
        gameElements.forEach(item=>{
            console.log(item);
            let gameItem={
                title:item.title,
                gameUrl:item.href,
                gameId:item.dataset.gameId,
                isBig:item.classList.contains('big'),
                img:item.querySelector('img').dataset.src,
                loading:true,
                category:item.dataset.category,
                amount: item.dataset.amount,
                eventLogo: item.dataset.eventLogo ? item.dataset.eventLogo : null
            };
            gamesData.push(gameItem);
        })
        return gamesData;
    }

    _renderBottomLabel(item){
        return html`<span class="game-category">${item.amount ? item.amount : '$0:00'}</span>`;
    }

    // loadGameStatData() {
    //     if(GRAPH_QL.ready){
    //         GRAPH_QL.getGameStat().then((gameStat)=>{
    //             this.processGameStat(gameStat);
    //
    //         }).catch((error)=>{
    //             console.log('mission data error',error);
    //         });
    //     }else{
    //         GRAPH_QL.addListener('ready',()=>{
    //             this.loadGameStatData();
    //         });
    //     }
    // }
    //
    // processGameStat(gameStat) {
    //     gameStat.map((stat)=> {
    //         this._data.games.map((game)=> {
    //             if (game.gameId == stat.game.id) {
    //                 game.bigWinAmount = stat.statValue.currency.symbol + '' + stat.statValue.amount;
    //             }
    //         })
    //     });
    //
    //     this.requestUpdate();
    // }
}

customElements.define('capp-big-winner-tile', CAPPGameTileBigWinners);