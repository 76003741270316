import {SubViewBase} from "./SubViewBase";
import {html} from "lit-element";
import {USER} from "../../core/User";
import {CAPPInput} from "../form-elements/CAPPInput";
import {CAPPSelect} from "../form-elements/CAPPSelect";
import {CAPPMultiselect} from "../form-elements/CAPPMultiselect";
import {CAPPDatepicker} from "../form-elements/CAPPDatepicker";
import {FormHelpers} from "../../util/FormHelpers";
import {CAPP_API} from "../../core/CAPPApi";
import {FORM_MESSAGE_TYPE} from "../form-elements/FormElementBase";
import {Validation} from "../../util/Validation";
import {APP_STRINGS} from "../../core/AppStrings";
import {ShowChatWindow} from "../../controllers/LivechatController";
import {Helpers} from "../../util/Helpers";


export class PersonalDetailsSubview extends SubViewBase{

    static FORM_ID = {
        PHONE:'phone',
        ADDRESS:'address',
        PASSWORD:'password',
        CONTACT_PREFS:'contactPreferences'
    }

    static MODE_TYPES={
        'STANDARD':'standard',
        'LOCAL_REGULATIONS':'localRegulations'
    }

    static MODE=PersonalDetailsSubview.MODE_TYPES.STANDARD;

    constructor() {
        super();

        this._activeForm=null;
        this._addressLookupMode=false;
        this._passwordValidationData={
            capitalLetterCheck: false,
            lengthCheck: false,
            numberCheck: false,
            passwordStrengthLabel:'',
            passwordStrength:0
        }
        this._countries=null;
        this._dialingCodes=null;
        this._commChannels=null;

        this._updateError=null;
        this._successView=false;
        this._successViewText="";


    }

    renderViewContent() {
        return html`
            <div class="pd-sub ${this._loading?'loading':''}">
                ${this.renderSubviewHeader()}
                ${this.renderProfileInfo()}
                ${this._renderActiveForm()}
                ${this._renderSuccessView()}
            </div>
        `;
    }

    renderSubviewHeader(){
        return html`
            <div class="sw-header-simple">
                <img class="close-btn" alt="Back Button Icon" src="${`${CDN_ASSETS_URL}/icons/left-arrow.svg`}" @click=${this._onBackButtonClick}>
                <img class="close-btn" alt="Chat Button Icon" src="${`${CDN_ASSETS_URL}/icons/livechat.svg`}" @click=${()=>{ShowChatWindow()}}>
            </div>
        `;

    }

    renderProfileInfo(){
        return html`
            <div class="sub-view-content">
                <section>
                    <h3>${APP_STRINGS.getWPMLString('PersonalDetailsSubView-title')}</h3>
                    <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-email')}" type="text" value="${USER.data.EmailAddress}" disabled></capp-input>
                    <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-firstName')}" type="text" value="${USER.data.FirstName}" disabled></capp-input>
                    <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-lastName')}" type="text" value="${USER.data.LastName}" disabled></capp-input>
                    <capp-datepicker label="${APP_STRINGS.getWPMLString('UserAccount-fields-dateOfBirth')}" value="${Helpers.createLocalDateFromISOString(USER.data.DateOfBirth)}" monthType="MMM" disabled></capp-datepicker>
                    <div class="phone-group input-wrap">
                        <div class="pg-grid">
                            <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-mobilePrefix')}" value="${USER.data.MobilePrefix==""?'-':`+${USER.data.MobilePrefix}`}" disabled></capp-input>
                            <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-phoneNumber')}" value="${USER.data.MobilePhone}" disabled ></capp-input>
                        </div>
                        ${USER.data.IsMobilePhoneOtpValidated==false?html`
                            <a class="text-link update-btn" .formId="${PersonalDetailsSubview.FORM_ID.PHONE}" @click=${this._onUpdateFormBtnClick}>${APP_STRINGS.getWPMLString('PersonalDetailsSubView-update')}</a>
                        `:''}
                    </div>
                    ${PersonalDetailsSubview.MODE==PersonalDetailsSubview.MODE_TYPES.STANDARD?html`
                        <div class="input-wrap">
                            <capp-text label="${APP_STRINGS.getWPMLString('UserAccount-fields-address')}" value="${this._createAddressDisplay()}" disabled></capp-text>
                            <a class="text-link update-btn" .formId="${PersonalDetailsSubview.FORM_ID.ADDRESS}" @click=${this._onUpdateFormBtnClick}>${APP_STRINGS.getWPMLString('PersonalDetailsSubView-update')}</a>
                        </div>
                    `:''}
                    
                    
                </section>
                <hr>
                <section>
                    <h4>${APP_STRINGS.getWPMLString('PersonalDetailsSubView-section-password')}</h4>
                    <div class="input-wrap">
                        <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-password')}"  type="text" value="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" disabled></capp-input>
                        <a class="text-link update-btn" .formId="${PersonalDetailsSubview.FORM_ID.PASSWORD}" @click=${this._onUpdateFormBtnClick}>${APP_STRINGS.getWPMLString('PersonalDetailsSubView-update')}</a>
                    </div>
                    
                </section>
                <hr>
                
                <section style="margin-bottom: 5em;">
                    <h4>${APP_STRINGS.getWPMLString('PersonalDetailsSubView-section-contactPreferences')}</h4>
                    ${USER.data.PlayerSocialMedias?html`
                        ${USER.data.PlayerSocialMedias.map(ch=>html`
                            <capp-input label="${ch.socialMediaName}" type="text" value="${ch.username}" disabled></capp-input>
                        `)}
                    `:``}
                    
                    <a class="text-link update-btn" .formId="${PersonalDetailsSubview.FORM_ID.CONTACT_PREFS}" @click=${this._onUpdateFormBtnClick}>${APP_STRINGS.getWPMLString('PersonalDetailsSubView-update')}</a>
                </section>
            </div>
        `;
    }

    _renderActiveForm(){
        let formTemplate=null;
        switch (this._activeForm){
            case PersonalDetailsSubview.FORM_ID.PHONE:
                formTemplate=this._templatePhoneForm();
                break;
            case PersonalDetailsSubview.FORM_ID.ADDRESS:
                formTemplate=this._templateAddressForm();
                break;
            case PersonalDetailsSubview.FORM_ID.PASSWORD:
                formTemplate=this._templatePasswordForm();
                break;
            case PersonalDetailsSubview.FORM_ID.CONTACT_PREFS:
                formTemplate=this._templateContactPreferencesForm();
                break;
            default:
                return '';
        }
        return html`
            <div class="sub-view-form">
                ${formTemplate}
            </div>
        `;
    }

    _templatePhoneForm(){
        return html`
                ${this._templateFormHeader(APP_STRINGS.getWPMLString('PersonalDetailsSubView-formTitle-phoneNumber'))}
                ${this._countries==null?html`
                    <capp-preloader></capp-preloader>
                `:html`
                    <form class="reg-form" @submit=${this._onFormSubmit} .formId=${PersonalDetailsSubview.FORM_ID.PHONE}>
                        <button type="submit" disabled style="display: none" aria-hidden="true"></button>
                        <div class="phone-group">
                            <div class="pg-grid">
                                <capp-select name="mobilePrefix" label="${APP_STRINGS.getWPMLString('UserAccount-fields-mobilePrefix')}" >
                                    ${this._dialingCodesSelectData.map(item=>html`
                                <option value="${item.value}" ?selected=${item.default}>${item.label}</option>
                            `)}
                                </capp-select>
                                <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-phoneNumber')}" name="mobilePhone" type="${CAPPInput.INPUT_TYPE.NUMBER}" value="${USER.data.MobilePhone}" @change=${this._handleInputChange}></capp-input>
                            </div>
                            ${this._phoneValid==false?html`
                            <div class="sa-msg message error"><img class="icon" alt="Warning Icon" src="${CDN_ASSETS_URL}/icons/warning.svg"><span>${APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-phone')}</span></div>
                        `:''}
                        </div>
                        ${this._updateError!=null?html`
                            <div class="sa-msg message error"><img class="icon" alt="Warning Icon" src="${CDN_ASSETS_URL}/icons/warning.svg"><span>${this._updateError}</span></div>
                        `:''}
                        <button type="submit" class="btn-primary ${this._loading?'loading':''} submit-btn">${APP_STRINGS.getWPMLString('PersonalDetailsSubView-update')}</button>
                    </form>
                `}
                
        `;
    }

    _templateAddressForm(){
        return html`
            ${this._templateFormHeader(APP_STRINGS.getWPMLString('PersonalDetailsSubView-formTitle-address'))}
            ${this._countries==null?html`
                    <capp-preloader></capp-preloader>
                `:html`
                <form class="reg-form" @submit=${this._onFormSubmit} .formId=${PersonalDetailsSubview.FORM_ID.ADDRESS}>
                    <button type="submit" disabled style="display: none" aria-hidden="true"></button>
                    ${this._addressLookupMode?this._renderAddressLookup():''}
                    ${this._renderManualAddressInput()}
                    ${this._updateError!=null?html`
                        <div class="sa-msg message error"><img class="icon" alt="Warning Icon" src="${CDN_ASSETS_URL}/icons/warning.svg"><span>${this._updateError}</span></div>
                    `:''}
                    <button type="submit" class="btn-primary ${this._loading?'loading':''} submit-btn">${APP_STRINGS.getWPMLString('PersonalDetailsSubView-update')}</button>
                </form>
            `}
        `;
    }

    _renderAddressLookup(){
        return html`
            <capp-input class="no-mb" label="Address" name="address" placeholder="Start typing your address..." type="text" @change=${this._onAddressLookupChange}></capp-input>
            <button class="manual-address-toggle" type="button" @click=${this._onToggleAddressLookup}><span>Enter manually</span></button>
        `;
    }

    _renderManualAddressInput(){
        return html`
            <div class="manual-address-input-group ${this._addressLookupMode?'':'active'}">
                <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-address1')}" name="address" type="text" value="${USER.data.Address1Display}" @change=${this._handleInputChange}></capp-input>
                <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-address2')}" name="address2" type="text" value="${USER.data.Address2Display?USER.data.Address2Display:''}" @change=${this._handleInputChange}></capp-input>
                <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-city')}" name="city" type="text" value="${USER.data.City}" @change=${this._handleInputChange}></capp-input>
                <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-country')}" name="countryName" type="text" value="${USER.data.CountryName}" disabled></capp-input>
                <capp-input class="no-mb" label="${APP_STRINGS.getWPMLString('UserAccount-fields-postCode')}" name="postcode" type="text" value="${USER.data.PostCode}" @change=${this._handleInputChange}></capp-input>
                <!--<button class="manual-address-toggle" type="button" @click=${this._onToggleAddressLookup}><span>Address look up</span></button>-->
            </div>
            
        `;
    }

    _createAddressDisplay(){
        let address="";
        address+=`${USER.data.Address1Display}\n`;
        address+=`${USER.data.City}\n`;
        address+=`${USER.data.CountryName}\n`;
        address+=`${USER.data.PostCode}`;
        return address;
    }

    _templatePasswordForm(){
        return html`
            ${this._templateFormHeader(APP_STRINGS.getWPMLString('PersonalDetailsSubView-formTitle-password'))}
            <form class="reg-form" @submit=${this._onPasswordFormSubmit} .formId=${PersonalDetailsSubview.FORM_ID.PASSWORD}>
                <button type="submit" disabled style="display: none" aria-hidden="true"></button>
                <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-currentPassword')}" name="currentPassword" type="password" @change=${this._handleInputChange} @keyup=${this._handleInputChange}></capp-input>
                <div class="password-group">
                    <capp-input label="${APP_STRINGS.getWPMLString('UserAccount-fields-newPassword')}" name="password" type="password" @change=${this._handleInputChange} @keyup=${this._handleInputChange}></capp-input>
                    ${this._renderPasswordValidations()}
                </div>
                ${this._updateError!=null?html`
                    <div class="sa-msg message error"><img class="icon" alt="Warning Icon" src="${CDN_ASSETS_URL}/icons/warning.svg"><span>${this._updateError}</span></div>
                `:''}
                <button type="submit" class="btn-primary ${this._loading?'loading':''} submit-btn">${APP_STRINGS.getWPMLString('PersonalDetailsSubView-update')}</button>
            </form>
                
        `;
    }

    _renderPasswordValidations(){
        return html`
            <div class="vcat">
                <div>${this._renderValidationIcon(this._passwordValidationData.lengthCheck)}<span>${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-characterNum')}</span></div>
                <div>${this._renderValidationIcon(this._passwordValidationData.capitalLetterCheck)}<span>${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-capitalLetter')}</span></div>
                <div>${this._renderValidationIcon(this._passwordValidationData.numberCheck)}<span>${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-numbers')}</span></div>
            </div>
            <capp-progress-bar value="${this._passwordValidationData.passwordStrength}" layout="slim"></capp-progress-bar>
            <div class="pw-strength"><span class="label">${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-weak')}</span><span class="value">${APP_STRINGS.getWPMLString('UserAccount-passwordValidation-strong')}</span></div>
        `;
    }

    _renderValidationIcon(value){
        if(value){
            return html`<img class="valid" alt="Check Icon" src="${`${CDN_ASSETS_URL}/icons/single-check.svg`}">`;
        }else{
            return html`<img alt="Cross Icon" src="${`${CDN_ASSETS_URL}/icons/close.svg`}">`;
        }
    }

    _templateContactPreferencesForm(){
        return html`
            ${this._templateFormHeader(APP_STRINGS.getWPMLString('PersonalDetailsSubView-formTitle-contactPreferences'))}
            ${this._commChannels==null?html`
                    <capp-preloader></capp-preloader>
                `:html`
                <form class="reg-form" @submit=${this._onContactPreferencesSubmit} .formId=${PersonalDetailsSubview.FORM_ID.CONTACT_PREFS}>
                    <div class="contact-preferences-group">
                            <capp-multiselect label="${APP_STRINGS.getWPMLString('UserAccount-fields-contactPreferences')}" name="commChannels" @change=${this._onCommChannelsChange}>
                                ${this._commChannels.map(channel=>html`
                                <option value="${channel.value}" ?selected=${channel.active}>${channel.label}</option>
                            `)}
                            </capp-multiselect>
                        </div>
                        ${this._commChannels.map(channel=>html`
                        ${channel.active?html`
                            <capp-input label="${channel.label}" name="${channel.value}" placeholder="${channel.placeholderText}" type="text" value="${channel.inputVal}" @change=${this._onChannelUsernameChange}></capp-input>
                        `:''}
                    `)}
                    <button type="submit" class="btn-primary ${this._loading?'loading':''} submit-btn">${APP_STRINGS.getWPMLString('PersonalDetailsSubView-update')}</button>
                </form>
            `}
            
        `;
    }

    _templateFormHeader(title){
        return html`
            <div class="sw-header-simple">
                <img class="close-btn" alt="Close Button Icon" src="${`${CDN_ASSETS_URL}/icons/left-arrow.svg`}" @click=${this._closeActiveForm}>
                <h4>${title}</h4>
                <img class="close-btn" alt="Chat Button Icon" src="${`${CDN_ASSETS_URL}/icons/livechat.svg`}" @click=${()=>{ShowChatWindow()}}>
            </div>
        `;
    }

    _renderSuccessView(){
        if(!this._successView){return}

        return html`<div class="success-view">
            <img class="success-icn" alt="Success Icon" src="${`${CDN_ASSETS_URL}/icons/circle-check.svg`}">
            <h3>${this._successViewText}</h3>
            <a class="btn-secondary continue-btn" @click=${this._onContinueBtnClick}>${APP_STRINGS.getWPMLString('PersonalDetailsSubView-continue')}</a>
        </div>`
    }

    _onUpdateFormBtnClick(e){
        this._openForm(e.target.formId);
    }

    _openForm(formId){
        this._loadCountryCodesInfo();
        this._activeForm=formId;
        this.requestUpdate();
    }
    _closeActiveForm(){
        this._activeForm=null;
        this.requestUpdate();
    }

    _loadCountryCodesInfo(){
        if(this._dialingCodes && this._countries && this._commChannels){
            return;
        }

        Promise.all([CAPP_API.getCountryCodeInfo(),CAPP_API.getSocialMediaList()]).then(data=>{

            this._dialingCodes=data[0].DialingCodes;
            this._processDialingCodes();
            this._countries=data[0].Countries;

            this._initCommChannels(data[1]);

            this.requestUpdate();
        })

    }

    _processDialingCodes(){

        this._dialingCodesSelectData=[];
        if(PersonalDetailsSubview.MODE==PersonalDetailsSubview.MODE_TYPES.LOCAL_REGULATIONS){
            this._dialingCodesSelectData.push(
                {label:"-",value:"",default:USER.data.MobilePrefix==""}
            );
        }
        this._dialingCodes.forEach(dialingCode=> {
            let dcSelect = {label: `+${dialingCode}`, value: dialingCode, default: USER.data.MobilePrefix==dialingCode}
            this._dialingCodesSelectData.push(dcSelect);
        });
    }

    _initCommChannels(channelsData){
        this._commChannels=[];
        channelsData.forEach(item=>{
            this._commChannels.push({
                label:item.display,
                value:`commChannel${item.value}`,
                backendValue:item.value,
                placeholderText:item.field_display,
                active:false,
                inputVal:""
            })
        })

        this._commChannelPropertyNamesList=this._commChannels.map(item=>item.value);
        if(USER.data.PlayerSocialMedias){
            USER.data.PlayerSocialMedias.forEach(mediaAcc=>{
                for(let i=0; i<this._commChannels.length; i++){
                    if(mediaAcc.socialMediaType==this._commChannels[i].backendValue){
                        this._commChannels[i].active=true;
                        this._commChannels[i].inputVal=mediaAcc.username;
                    }
                }
            })
        }

    }

    _handleInputChange(e){
        this._validateInputElement(e.target);
    }

    _validateInputElement(inputEl){
        let valid=true;
        let r;

        /*
        let commChIndex=this._commChannelPropertyNamesList.indexOf(inputEl.name);
        if(commChIndex>=0){
            if(inputEl.value.length>1){
                valid=inputEl.valid=true;
                inputEl.message=null;
            }else{
                valid=inputEl.valid=false;
                inputEl.message=`Please enter your ${this._commChannels[commChIndex].label} username`;
                inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
            }
        }*/

        switch (inputEl.name){
            case 'password':
                r=Validation.validatePassword(inputEl.value);
                valid=r.valid;
                if(r.valid){
                    inputEl.valid=true;
                }else{
                    inputEl.valid=false;
                }
                inputEl.message=null;
                this._passwordValidationData=r;
                this.requestUpdate();
                break;
            case 'currentPassword':
                if(inputEl.value.length>0){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-currentPassword');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                }
                break;
            case 'mobilePhone':
                if(inputEl.value && inputEl.value.toString().length>=5){
                    this._phoneValid=true;
                    valid=inputEl.valid=true;
                }else{
                    this._phoneValid=false;
                    valid=inputEl.valid=false;
                }

                break;
            case 'address':
                if(inputEl.value.length>1){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-address');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                    this._addressLookupMode=false;
                }
                break;
            case 'city':
                if(inputEl.value.length>1){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-city');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                    this._addressLookupMode=false;
                }
                break;
            case 'countryISO':
                if(inputEl.value){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-country');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                    this._addressLookupMode=false;
                }
                break;
            case 'postcode':
                if(inputEl.value.length>1){
                    valid=inputEl.valid=true;
                    inputEl.message=null;
                }else{
                    valid=inputEl.valid=false;
                    inputEl.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-postCode');
                    inputEl.messageType=FORM_MESSAGE_TYPE.ERROR;
                    this._addressLookupMode=false;
                }
                break;
            default:
                //console.log('validate',inputEl);
        }
        this.requestUpdate();
        return valid;
    }

    _setLoading(value){
        this._loading=value;
        this.requestUpdate();
    }

    _onFormSubmit(e){
        e.preventDefault();

        let form=e.target;
        let formElements=FormHelpers.getFormElements(form);

        let valid=true;
        formElements.forEach(el=>{
            let res=this._validateInputElement(el);
            valid=valid&res;
        })


        if(!valid){
            this.requestUpdate();
            return;
        }

        let formData=FormHelpers.getFormDataAsObject(form);



        this._updateError=null;
        this._setLoading(true);

        switch (form.formId){
            case PersonalDetailsSubview.FORM_ID.PHONE:
            case PersonalDetailsSubview.FORM_ID.ADDRESS:
                this._updateUserData(formData,form.formId);
                break;
            default:

        }
    }

    _updateUserData(formData,formId){

        let currentData={
            mobilePhone:USER.data.MobilePhone,
            mobilePrefix:USER.data.MobilePrefix,
            address:USER.data.Address1Display,
            address2:USER.data.Address2Display,
            city:USER.data.City,
            countryISO:USER.data.CountryISO,
            postcode:USER.data.PostCode
        }



        formData=Object.assign(currentData,formData);
        formData.sessionKey=USER.sessionKey;

        if(USER.phoneNumberVerified){
            delete formData.mobilePhone;
            delete formData.mobilePrefix;

        }


        USER.updatePlayerInfo(formData).then(data=>{

            if(typeof data == "undefined"){
                this._setLoading(false);
                return;
            }

            if(data.error){
                this._updateError=data.error.text;
                this._setLoading(false);
            }else{
                USER.updateUserData().then(data=>{
                    this._successView=true;
                    this._successViewText=this._getSuccessViewTitle(formId);
                    this._setLoading(false);
                })
            }
        })
    }

    _onPasswordFormSubmit(e){
        e.preventDefault();
        let form=e.target;
        let formElements=FormHelpers.getFormElements(form);

        let valid=true;
        formElements.forEach(el=>{
            let res=this._validateInputElement(el);
            valid=valid&res;

            if(!res && el.name=='password'){
                el.message=APP_STRINGS.getWPMLString('UserAccount-fieldInvalid-password');
                el.messageType=FORM_MESSAGE_TYPE.ERROR;
            }
        })


        if(!valid){
            this.requestUpdate();
            return;
        }

        let formData=FormHelpers.getFormDataAsObject(form);
        this._setLoading(true);

        USER.changePassword(formData.currentPassword,formData.password).then(data=>{
            if(data.error){
                this._updateError=data.error.text;
            }else{
                this._successView=true;
                this._successViewText=this._getSuccessViewTitle(PersonalDetailsSubview.FORM_ID.PASSWORD);
            }
            this._setLoading(false);
        })
    }

    _onContactPreferencesSubmit(e){
        e.preventDefault();
        let channelsData={};
        let chIndex=0;
        this._commChannels.forEach(ch=>{
            if(ch.active){
                channelsData[`socialMediaArray[${chIndex}][type]`]=ch.backendValue;
                channelsData[`socialMediaArray[${chIndex}][value]`]=ch.inputVal;
                chIndex++;
            }
            else if(ch.active === false && ch.inputVal !== '') {
                channelsData[`socialMediaArray[${chIndex}][type]`]=ch.backendValue;
                channelsData[`socialMediaArray[${chIndex}][value]`]="";
                chIndex++;
            }
        })
        this._updateError=null;
        this._setLoading(true);
        this._updateUserData(channelsData,PersonalDetailsSubview.FORM_ID.CONTACT_PREFS);

    }

    _getSuccessViewTitle(formId){
        switch (formId){
            case PersonalDetailsSubview.FORM_ID.PHONE:
                return APP_STRINGS.getWPMLString('PersonalDetailsSubView-updateSuccess-phoneNumber');
                break;
            case PersonalDetailsSubview.FORM_ID.ADDRESS:
                return APP_STRINGS.getWPMLString('PersonalDetailsSubView-updateSuccess-address');
                break;
            case PersonalDetailsSubview.FORM_ID.PASSWORD:
                return APP_STRINGS.getWPMLString('PersonalDetailsSubView-updateSuccess-password');
                break;
            case PersonalDetailsSubview.FORM_ID.CONTACT_PREFS:
                return APP_STRINGS.getWPMLString('PersonalDetailsSubView-updateSuccess-contactPreferences');
                break;
        }
    }

    _onCommChannelsChange(e){
        this._commChannels.forEach(item=>{
            item.active=e.detail.indexOf(item.value)>=0;
        })
        this.requestUpdate();
    }

    _onChannelUsernameChange(e){

        for(let i=0; i<this._commChannels.length; i++){
            if(this._commChannels[i].value==e.target.name){
                this._commChannels[i].inputVal=e.detail;
                break;
            }
        }
    }

    _onContinueBtnClick(){
        this._successView=null;
        this._activeForm=null;
        this.requestUpdate();
    }

    _onBackButtonClick(){
        window.history.back();
    }



}

customElements.define('personal-details-sub',PersonalDetailsSubview);