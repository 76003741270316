import {POPUP_DESKTOP_ALIGN, POPUP_MOBILE_ALIGN, PopupBase} from "./PopupBase";
import {html} from "lit-element";
import {USER} from "../../core/User";
import {APP_STRINGS} from "../../core/AppStrings";
import {CAPPPopup} from "./CAPPPopup";
import {NewPasswordPopup} from "./NewPasswordPopup";

export class PasswordResetProcessorPopup extends PopupBase{
    constructor(resetData) {
        super(POPUP_MOBILE_ALIGN.TOP,POPUP_DESKTOP_ALIGN.CENTER);
        this._processing=true;
        this._processResetCode(resetData)
    }

    _processResetCode(data){
        try {
            let decoded=JSON.parse(atob(data));
            this._doReset(decoded);
        }catch (error){
            setTimeout(()=>{this._close();})
            window.history.pushState({}, document.title, window.location.pathname);
            new CAPPPopup(
                APP_STRINGS.getWPMLString('Common-Error'),
                APP_STRINGS.getWPMLString('resetPasswordProcessorPopup-invalidResetCodeMessage'),
                [{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-secondary'}]
            )
        }
    }

    _doReset(data){
        USER.playerResetPasswordWithResetCode(data.emailAddress,data.passwordResetCode)
            .then(data=>{
                this._close();
                window.history.pushState({}, document.title, window.location.pathname);
                if(data.error){
                    new CAPPPopup(
                        APP_STRINGS.getWPMLString('Common-Error'),
                        data.error.text,
                        [{label:APP_STRINGS.getWPMLString('Common-OK'),type:'btn-secondary'}],
                        ()=>{
                            window.location.href="#reset-password";
                        }
                    )
                }else{
                    this._openNewPasswordPopup(data);
                }
            })
    }

    _openNewPasswordPopup(data){
        new NewPasswordPopup(data.EmailAddress,data.UserPassword,data.sessionKey);
    }

    _renderContent(){
        return html`
            <div class="bonus-claim-popup-content">
                ${this._renderProcessingLayout()}
            </div>
        `;
    }

    _renderProcessingLayout(){
        return html`
            <div class="processing">
                <span>${APP_STRINGS.getWPMLString('resetPasswordProcessorPopup-processingMessage')}</span>
                <capp-preloader></capp-preloader>
            </div>
        `;
    }
    _close(){
        super._close();
    }
}

customElements.define('password-reset-processor-popup',PasswordResetProcessorPopup);